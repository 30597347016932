import { useEffect, useRef } from 'react';
import useWindowSize from '../hooks/useWindowSize';

interface HeroParallaxProps {
	title: string;
	frontImage: string;
	backImage: string;
}

const HeroParallax = (props: HeroParallaxProps) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const backImage = useRef<HTMLImageElement>(null);
	const windowSize = useWindowSize();

	const slopeCalculation = (delta: number) => {
		return (delta * 2) / 100;
	};

	const containerScrollProgress = (scrollPosition: number, height: number, tolerance: number = 0) => {
		const progress = (scrollPosition / height) * 100;
		if (progress > 100 + tolerance) {
			return 100 + tolerance;
		} else if (progress < 0 - tolerance) {
			return 0 - tolerance;
		} else {
			return progress;
		}
	};

	const translationCalculation = (delta: number, height: number, scrollPosition: number) => {
		if (delta < 0) {
			throw new Error('Delta must be positive');
		} else {
			return containerScrollProgress(scrollPosition, height, 20) * slopeCalculation(delta) - delta;
		}
	};

	const scrollListener = () => {
		if (containerRef.current) {
			const rect = containerRef.current.getBoundingClientRect();
			if ((rect.bottom >= 0 || rect.top >= 0) && (rect.left >= 0 || rect.right >= 0)) {
				const topPosition = containerRef.current.offsetTop;
				const scrollPosition = window.scrollY;
				const height = rect.height;
				const containerScrollPosition = scrollPosition - topPosition;

				if (backImage.current) {
					backImage.current.style.transform = `translateY(${translationCalculation(50, height, containerScrollPosition)}%)`;
				}
			}
		}
	};

	useEffect(() => {
		//Scroll listener
		document.addEventListener('scroll', scrollListener);
		return () => {
			document.removeEventListener('scroll', scrollListener);
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		//SetUp
		if (backImage.current && containerRef.current) {
			const rect = backImage.current.getBoundingClientRect();
			backImage.current.style.marginTop = `-${rect.height / 2}px`;
			scrollListener();
		}
		// eslint-disable-next-line
	}, [windowSize, backImage, containerRef]);

	return (
		<section className='hero-parallax'>
			<div className='hero-parallax--content container'>
				<h1 className='hero-parallax--title'>{props.title}</h1>
				<div className='hero-parallax--images' ref={containerRef}>
					<img src={props.frontImage} aria-hidden='true' alt='' className='hero-parallax--image-front' />
					<img src={props.backImage} aria-hidden='true' alt='' className='hero-parallax--image-back' ref={backImage} />
				</div>
			</div>
		</section>
	);
};

export default HeroParallax;

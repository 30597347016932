import { Link } from 'react-router-dom';
import Logo from '../assets/logo/footer-logo.svg';
import AppStoreLogo from '../assets/logo/appstore.svg';
import GooglePlayLogo from '../assets/logo/google-play.png';

const Footer = () => {
	return (
		<footer className='footer'>
			<div className='container'>
				<section className='row footer-head'>
					<figure className='footer-logo'>
						<img src={Logo} alt='Social O Matic' />
					</figure>
				</section>
				<section className='row footer-main'>
					<div className='col-xs-4 col-sm-12 col-md-3'>
						<ul className='footer-nav'>
							<li>
								<Link to='/'>Accueil</Link>
							</li>
							<li>
								<Link to='/about'>A propos</Link>
							</li>
							<li>
								<Link to='/pro'>Professionnel</Link>
							</li>
							<li>
								<Link to='/security'>Sécurité</Link>
							</li>
							<li>
								<Link to='/privacy-policy'>Politique de confidentialité</Link>
							</li>
							<li>
								<a href='mailto: contact@social-o-matic.com'>Nous contacter</a>
							</li>
						</ul>
					</div>
					<div className='col-xs-4 col-sm-12 col-md-3'>
						<h5 className='footer-download-title'>Télécharger l'app</h5>
						<p className='footer-download-text'>
							Rejoignez la communauté Social O Matic dès maintenant en vous inscrivant sur notre site ou sur notre application mobile.
						</p>
						<ul className='footer-download-links'>
							<li className='footer-download-link'>
								<a href='https://apps.apple.com/us/app/social-o-matic/id1628987685' target='_blank' rel='noreferrer'>
									<img src={AppStoreLogo} alt="Disponible sur l'Apple Store" />
								</a>
							</li>
							<li className='footer-download-link'>
								<a
									href='https://play.google.com/store/apps/details?id=dev.borgognon.socialomatic&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
									rel='noreferrer'
									target='_blank'>
									<img alt='Disponible sur Google Play' src={GooglePlayLogo} />
								</a>
							</li>
						</ul>
					</div>
				</section>
				<section className='row footer-legal'>
					<p>&copy; Thomas Borgognon - {new Date().getFullYear()}</p>
				</section>
			</div>
		</footer>
	);
};

export default Footer;

import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import Loader from '../../components/Loader';
import Notice from '../../components/Notice';
import Switch from '../../components/Switch';
import { useAuth } from '../../context/AuthContext';
import { delete_user } from '../../tools/server/auth/auth';
import { UNAUTHORIZED } from '../../tools/server/errors';

const DeleteAccount = () => {
	const { authState, authDispatch } = useAuth();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const handleSubmit = async (values: any) => {
		setIsLoading(true);
		setError(null);
		try {
			await delete_user(authState.token, values.password);
			localStorage.removeItem('token');
			localStorage.removeItem('refreshToken');
			authDispatch({ type: 'LOGOUT' });
			setIsLoading(false);
		} catch (error) {
			switch (error) {
				case UNAUTHORIZED:
					setError('Veuillez vérifier votre mot de passe');
					break;
				default:
					setError('Une erreur est survenue lors de la suppression du compte. Veuillez réessayer.');
					break;
			}
			setIsLoading(false);
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<Formik
			initialValues={{
				password: '',
				confirm: false,
			}}
			onSubmit={(values) => {
				handleSubmit(values);
			}}>
			<Form>
				{error && (
					<div className='form-group'>
						<Notice type='error'>{error}</Notice>
					</div>
				)}
				<div className='form-group'>
					<label>
						Êtes-vous sûr de vouloir supprimer votre compte ?<br />
						En supprimant votre compte, automatiquement, toutes vos données seront immédiatement supprimées de nos serveurs.
						<br />
						<b>Cette action est irréversible !</b>
					</label>
				</div>
				<div className='form-group'>
					<Switch name='confirm' id='confirm' label='Je confirme vouloir supprimer mon compte' />
				</div>
				<div className='form-group'>
					<label htmlFor='password'>Mot de passe</label>
					<Field type='password' name='password' id='password' />
				</div>
				<div className='form-group form-group-submit'>
					<button type='submit' className='button--primary'>
						Supprimer mon compte
					</button>
				</div>
			</Form>
		</Formik>
	);
};

export default DeleteAccount;

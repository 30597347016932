import { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import RestrictedArea from '../../components/RestrictedArea';
import { useRegister } from '../../context/RegisterContext';
import useWindowSize from '../../hooks/useWindowSize';
import UserDetails from './steps/UserDetails';
import ValidateEmail from './steps/ValidateEmail';

const RegistrationDispatcher = () => {
	const { registerState, registerDispatch } = useRegister();
	const navigate = useNavigate();
	const windowSize = useWindowSize();

	useEffect(() => {
		if (registerState.step > 2) {
			registerDispatch({ type: 'RESET' });
			navigate('/profil');
		}
		// eslint-disable-next-line
	}, [registerState]);

	return (
		<RestrictedArea needAuth={false}>
			<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA ? process.env.REACT_APP_CAPTCHA : ''}>
				<div
					className='registration-dispatcher'
					style={{
						minHeight: windowSize.height,
					}}>
					<div className='container'>
						<div className='row'>
							<div className='col-xs-4 col-sm-12 col-md-3 registration-dispatcher-animation'>
								<Lottie
									options={{
										animationData: require('../../assets/lottie/illustrations_social_portable.json'),
										loop: true,
									}}
								/>
							</div>
							<div className='col-xs-4 col-sm-12 col-md-1' />
							<div className='col-xs-4 col-sm-12 col-md-8 registration-dispatcher-view'>
								{registerState.step === 1 ? <UserDetails /> : registerState.step === 2 ? <ValidateEmail /> : <></>}
							</div>
						</div>
					</div>
				</div>
			</GoogleReCaptchaProvider>
		</RestrictedArea>
	);
};

export default RegistrationDispatcher;

import { get_app_url } from './server/vars';

//Mark: - Base
export const BASE_API_URL: string = get_app_url() + '/api';

// MARK: - Auth
export const LOGIN_URL: string = get_app_url() + '/oauth/token';
export const LOGOUT_URL: string = BASE_API_URL + '/user/logout';
export const REGISTER_URL: string = BASE_API_URL + '/user/create';
export const RESET_PASSWORD_URL: string = BASE_API_URL + '/user/reset-password';
export const CHECK_EMAIL: string = BASE_API_URL + '/user/check-email';

// MARK: - User
export const USER_URL: string = BASE_API_URL + '/user';
export const USER_UPDATE_URL: string = BASE_API_URL + '/user/update';
export const USER_DELETE_URL: string = BASE_API_URL + '/user/delete';

// MARK: - Verification code
export const VERIFICATION_CODE_CREATE_URL: string = BASE_API_URL + '/validation/create';
export const VERIFICATION_CODE_CHECK_URL: string = BASE_API_URL + '/validation/check';

// MARK: - Places
export const PLACE_URL: string = BASE_API_URL + '/place';
export const PLACE_CONTROLLED_URL: string = BASE_API_URL + '/place/my-place';
export const PLACE_CREATE_URL: string = BASE_API_URL + '/place/create';
export const PLACE_UPDATE_URL: string = BASE_API_URL + '/place/update';
export const PLACE_ADMIN_URL: string = BASE_API_URL + '/place/admin';
export const PLACE_USER_URL: string = BASE_API_URL + '/place/users';
export const PLACE_SEARCH_URL: string = BASE_API_URL + '/place/search';
export const PLACE_LOCATE_URL: string = BASE_API_URL + '/place/locate';
export const PLACE_FOLLOW_URL: string = BASE_API_URL + '/follow';
export const PLACE_RECOMMENDATION_URL: string = BASE_API_URL + '/recommendation';

// MARK: - Posts
export const POST_URL: string = BASE_API_URL + '/post';
export const POST_CREATE_URL: string = BASE_API_URL + '/post';
export const POST_DELETE_URL: string = BASE_API_URL + '/post';

// MARK: - Types
export const TYPE_URL: string = BASE_API_URL + '/type';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { AuthAction, useAuth } from '../../context/AuthContext';
import * as Yup from 'yup';
import { update_user } from '../../tools/server/auth/auth';
import Notice from '../../components/Notice';
import Loader from '../../components/Loader';
import { USERNAME_REJECTED } from '../../tools/server/errors';

const Username = () => {
	const { authState, authDispatch } = useAuth();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const validationSchema = Yup.object().shape({
		username: Yup.string().required('Obligatoire').min(3, 'Doit contenir au moins 3 caractères'),
	});

	const handleSubmit = async (values: any) => {
		setErrorMessage(null);
		setIsLoading(true);
		try {
			const response = await update_user(authState.token, undefined, undefined, values.username);
			if (response !== null) {
				const update: AuthAction = {
					type: 'RESTORE_SESSION',
					payload: {
						...authState,
						username: response.username,
					},
				};
				authDispatch(update);
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		} catch (errors: any) {
			if (errors.username === USERNAME_REJECTED) {
				setErrorMessage("Ce nom d'utilisateur est déjà pris.");
			} else {
				setErrorMessage('Une erreur est survenue lors de la mise à jour. Veuillez réessayer.');
			}
			setIsLoading(false);
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<Formik
			initialValues={{
				username: authState.username,
			}}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				handleSubmit(values);
			}}>
			<Form>
				{errorMessage && (
					<div className='form-group'>
						<Notice type='error'>{errorMessage}</Notice>
					</div>
				)}
				<div className='form-group'>
					<label htmlFor='username'>Nom d'utilisateur</label>
					<Field name='username' />
					<ErrorMessage name='username' component='div' className='invalid-feedback' />
				</div>
				<div className='form-group form-group-submit'>
					<button type='submit' className='button--black'>
						Sauvegarder
					</button>
				</div>
			</Form>
		</Formik>
	);
};

export default Username;

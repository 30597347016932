import Lottie from 'react-lottie';

interface LoaderProps {
	className?: string;
}

const Loader = (props: LoaderProps) => {
	return (
		<div className={`loader${props.className ? ` ${props.className}` : ''}`}>
			<Lottie
				options={{
					animationData: require('../assets/lottie/loading-social.json'),
					loop: true,
				}}
			/>
		</div>
	);
};

export default Loader;

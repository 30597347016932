import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import Page from '../../../components/Page';
import * as Yup from 'yup';
import { usePasswordContext } from '../../../context/PasswordContext';
import { useState } from 'react';
import { send_verification_code } from '../../../tools/server/auth/auth';
import { ALREADY_SENDED, EMAIL_REJECTED } from '../../../tools/server/errors';
import Loader from '../../../components/Loader';
import Notice from '../../../components/Notice';

const RequestPassword = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const { passwordDispatch } = usePasswordContext();

	const validationSchema = Yup.object().shape({
		email: Yup.string().required('Ce champ est obligatoire').email('Ce champ doit être une adresse mail valide'),
	});

	const handle = (values: any) => {
		setIsLoading(true);
		setError(null);
		send_verification_code(values.email, true)
			.then(() => {
				passwordDispatch({ type: 'SET_EMAIL', payload: { email: values.email } });
				passwordDispatch({ type: 'NEXT_STEP' });
				setIsLoading(false);
			})
			.catch((error) => {
				switch (error) {
					case EMAIL_REJECTED: {
						setError("Cette adresse mail n'est pas enregistrée");
						break;
					}
					case ALREADY_SENDED: {
						passwordDispatch({ type: 'SET_EMAIL', payload: { email: values.email } });
						passwordDispatch({ type: 'NEXT_STEP' });
						break;
					}
					default: {
						setError('Une erreur est survenue');
						break;
					}
				}
				setIsLoading(false);
			});
	};

	return (
		<Page pageClass='reset-password' title='Mot de passe oublié'>
			<h1 className='page-reset-password-title'>Mot de passe oublié</h1>
			{error && <Notice type='error'>{error}</Notice>}
			{isLoading ? (
				<Loader />
			) : (
				<Formik
					initialValues={{ email: '' }}
					validationSchema={validationSchema}
					onSubmit={(values) => {
						handle(values);
					}}>
					<Form>
						<div className='form-group'>
							<label htmlFor='email'>E-mail</label>
							<Field type='email' name='email' id='email' />
							<ErrorMessage name='email' component='div' className='form-error' />
						</div>
						<div className='form-group form-group-submit'>
							<button type='submit' className='form-circular-button'>
								<i className='fa-solid fa-arrow-right'></i>
							</button>
							<Link to='/login' className='button--black'>
								Retour
							</Link>
						</div>
					</Form>
				</Formik>
			)}
		</Page>
	);
};

export default RequestPassword;

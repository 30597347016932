import { useState } from 'react';
import Loader from '../../../components/Loader';
import Notice from '../../../components/Notice';
import Page from '../../../components/Page';
import VerificationCode from '../../../components/VerificationCode';
import { usePasswordContext } from '../../../context/PasswordContext';
import { validate_code } from '../../../tools/server/auth/auth';
import { BAD_CODE } from '../../../tools/server/errors';

const ConfirmEmail = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const { passwordDispatch, passwordState } = usePasswordContext();

	const handle = (value: any) => {
		setIsLoading(true);
		setError(null);
		validate_code(value, passwordState.email)
			.then(() => {
				passwordDispatch({ type: 'SET_CODE', payload: { code: value } });
				passwordDispatch({ type: 'NEXT_STEP' });
				setIsLoading(false);
			})
			.catch((error: string) => {
				switch (error) {
					case BAD_CODE: {
						setError("Le code est incorrect ou n'est plus valide");
						break;
					}
					default: {
						setError('Une erreur est survenue');
						break;
					}
				}
				setIsLoading(false);
			});
	};

	return (
		<Page pageClass='reset-password' title='Confirmer son adresse e-mail'>
			<h1 className='page-reset-password-title'>Confirmer son adresse e-mail</h1>
			<p className='page-reset-password-sub-title'>
				Pour réinitialiser votre compte, veuillez indiquer le code à 6 chiffres reçu dans votre boite e-mail. (Pensez à vérifier vos spams)
			</p>
			{error && <Notice type='error'>{error}</Notice>}
			{isLoading ? (
				<Loader />
			) : (
				<div className='page-reset-password-code'>
					<VerificationCode
						result={(value) => {
							handle(value);
						}}
						email={passwordState.email}
						forPassword={true}
						noSendAtBoot={true}
					/>
				</div>
			)}
		</Page>
	);
};

export default ConfirmEmail;

/**
 * Function used to check if an email is valid
 * @param email
 * @returns If the email is valid
 */
export const checkEmail = (email: string): boolean => {
	// eslint-disable-next-line
	const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
	if (reg.test(email) === false) {
		return false;
	} else {
		return true;
	}
};

/**
 * Function used to check if a password is valid
 * @param password
 * @returns If the password is valid
 */
export const checkPassword = (password: string): boolean => {
	// eslint-disable-next-line
	const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\s\S]{8,}$/;
	if (reg.test(password) === false) {
		return false;
	} else {
		return true;
	}
};

export const changeMetaTitle = (title: string | null) => {
	if (title !== null) {
		document.title = `Social O Matic | ${title}`;
	} else {
		document.title = 'Social O Matic';
	}
};

export const dataURLtoFile = (dataurl: string, filename: string) => {
	var arr = dataurl.split(','),
		mime = arr[0].match(/:(.*?);/)![1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
};

/**
 * Function used to generate a string date from a date
 * @param date The date to convert
 * @param withDay If you want to show the day
 * @returns The string date
 */
export const dateGenerator = (date: Date, withDay: boolean = false): string => {
	const months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'setpembre', 'octobre', 'novembre', 'décembre'];
	const days = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

	let result: string = `${date.getDate()} ${months[date.getMonth()]} à ${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${
		date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
	}`;
	if (withDay) {
		result = `${days[date.getDay() - 1]} ${result}`;
	}
	return result;
};

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/logo/navbar-logo.png';
import { useAuth } from '../context/AuthContext';

const Navbar = () => {
	const [plainNav, setPlainNav] = useState<boolean>(false);
	const [navOpen, setNavOpen] = useState<boolean>(false);
	const { authState } = useAuth();

	const scrollListener = () => {
		if (window.scrollY > 10) {
			setPlainNav(true);
		} else {
			setPlainNav(false);
		}
	};

	const closeNav = () => {
		setNavOpen(false);
	};

	useEffect(() => {
		window.addEventListener('scroll', scrollListener);

		return () => {
			window.removeEventListener('scroll', scrollListener);
		};
	}, []);

	return (
		<nav className={`navbar${plainNav ? ' navbar-plain' : ''}`}>
			<div className='container'>
				<div className='navbar-header'>
					<Link to='/' className='navbar-logo'>
						<img src={Logo} alt='Social O Matic' />
					</Link>
				</div>
				<ul className={`navbar-links${navOpen ? ' navbar-links-open' : ''}`}>
					<li>
						<Link to='/about' onClick={closeNav}>
							À propos
						</Link>
					</li>
					<li>
						<Link to='/security' onClick={closeNav}>
							Sécurité
						</Link>
					</li>
					<li>
						<Link to='/pro' onClick={closeNav}>
							Professionnels
						</Link>
					</li>
					<li className='navbar--dropdown'>
						<Link to='/my-account' onClick={closeNav}>
							<i className='fa-solid fa-user' />
							{authState.token === null ? <span>Mon compte</span> : <span>{authState.firstName}</span>}
						</Link>
						<ul className='navbar--dropdown-content'>
							{authState.token === null ? (
								<>
									<li>
										<Link to='/login' onClick={closeNav}>
											Se connecter
										</Link>
									</li>
									<li>
										<Link to='/register' onClick={closeNav}>
											Inscription
										</Link>
									</li>
								</>
							) : (
								<>
									<li>
										<Link to='/my-account' onClick={closeNav}>
											Mon compte
										</Link>
									</li>
									<li>
										<Link to='/logout' onClick={closeNav}>
											Me déconnecter
										</Link>
									</li>
								</>
							)}
						</ul>
					</li>
					<li>
						<Link to='/place' className='button--primary' onClick={closeNav}>
							<i className='fa-solid fa-magnifying-glass' /> Rechercher
						</Link>
					</li>
				</ul>
				<button className={`navbar-button${navOpen ? ' navbar-button-open' : ''}`} onClick={() => setNavOpen(!navOpen)}>
					<span className='navbar-button-line' />
					<span className='navbar-button-line' />
					<span className='navbar-button-line' />
				</button>
			</div>
		</nav>
	);
};

export default Navbar;

interface SectionProps {
	children?: React.ReactNode;
	color: 'default' | 'white';
	className?: string;
}

const Section = (props: SectionProps) => {
	return (
		<section className={`section${props.color === 'white' ? ' section-white' : ''}${props.className ? ` ${props.className}` : ''}`}>
			<div className='container'>
				<div className='row'>{props.children}</div>
			</div>
		</section>
	);
};

export default Section;

import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface ReinsuranceProps {
	title?: string;
	items: Array<ReinsuranceItem>;
}

interface ReinsuranceItem {
	title: string;
	icon?: string;
	content: ReactNode;
	cta?: {
		text: string;
		link: string;
		local: boolean;
		color: 'primary' | 'white' | 'black';
	};
}

const Reinsurance = (props: ReinsuranceProps) => {
	const { title, items } = props;

	return (
		<section className='reinsurance'>
			<div className='container'>
				{title && <h3 className='reinsurance--title'>{title}</h3>}
				<div className='row'>
					{items.map((item, index) => (
						<div key={index} className='col-xs-4 col-sm-12 col-md-6 reinsurance--item'>
							<div className='reinsurance--item-head'>
								<h4 className='reinsurance--item-title'>{item.title}</h4>
								{item.icon && <img src={item.icon} alt='' aria-hidden={true} className='reinsurance--item-icon' />}
							</div>
							<div className='reinsurance--item-content'>{item.content}</div>
							{item.cta && item.cta.local ? (
								<Link to={item.cta.link} className={`button--${item.cta.color}`}>
									{item.cta.text}
								</Link>
							) : (
								item.cta && (
									<a href={item.cta.link} target='_blank' rel='noreferrer' className={`button--${item.cta.color}`}>
										{item.cta.text}
									</a>
								)
							)}
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Reinsurance;

import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import PlaceHolder from '../assets/images/icons/placeholder-place-icon.svg';

interface ImageUploadProps {
	id: string;
	name: string;
	label: string;
	currentImage: string | null;
}

const ImageUpload = (props: ImageUploadProps) => {
	const [imagePreview, setImagePreview] = useState<string>(PlaceHolder);
	const { setFieldValue } = useFormikContext();

	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files[0]) {
			const reader = new FileReader();
			reader.onload = (e) => {
				if (e.target) {
					setImagePreview(e.target.result as string);
					setFieldValue(props.name, e.target.result);
				}
			};
			reader.readAsDataURL(event.target.files[0]);
		}
		setFieldValue(props.name, '');
	};

	useEffect(() => {
		if (props.currentImage !== null) {
			setImagePreview(props.currentImage);
			setFieldValue(props.name, props.currentImage);
		} else if (imagePreview === PlaceHolder && props.currentImage === null) {
			setImagePreview(PlaceHolder);
			setFieldValue(props.name, '');
		}
		// eslint-disable-next-line
	}, [props.currentImage]);

	return (
		<div className='image-upload'>
			<input type='file' name={props.name} id={props.id} className='image-upload--input' onChange={handleImageChange} accept='.jpg,.jpeg,.png' />
			<label htmlFor={props.id} className='image-upload--container'>
				<span className='image-upload--label'>{props.label}</span>
				<figure className='image-upload--preview'>
					<img src={imagePreview} alt='' />
				</figure>
				<span className='image-upload--instruction'>Cliquez sur l'image pour en télécharger une nouvelle.</span>
			</label>
		</div>
	);
};

export default ImageUpload;

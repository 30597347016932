import axios, { AxiosResponse } from 'axios';
import {
	PLACE_CONTROLLED_URL,
	TYPE_URL,
	PLACE_URL,
	PLACE_UPDATE_URL,
	PLACE_CREATE_URL,
	PLACE_ADMIN_URL,
	PLACE_SEARCH_URL,
	POST_URL,
	PLACE_USER_URL,
	POST_CREATE_URL,
	PLACE_FOLLOW_URL,
	PLACE_RECOMMENDATION_URL,
} from '../../endpoints';
import { dataURLtoFile } from '../../metaTools';
import { Pagination } from '../../models/pagination';
import { Place } from '../../models/place';
import { Post } from '../../models/post';
import { ResponseManager } from '../../models/responseManager';
import { PlaceType } from '../../models/type';
import { User } from '../../models/user';
import {
	AVATAR_NOT_VALID,
	NETWORK_ERROR,
	NO_MODIFICATIONS,
	PLACE_ALREADY_FOLLOWED,
	PLACE_ALREADY_RECOMMENDED,
	PLACE_NOT_FOLLOWED,
	PLACE_NOT_FOUND,
	PLACE_NOT_RECOMMENDED,
	SERVER_ERROR,
	UNAUTHORIZED,
	USER_ALREADY_ADMIN,
	USER_NOT_ADMIN,
	USER_NOT_FOUND,
	WEBSITE_NOT_VALID,
} from '../errors';
import { headerGenerator } from '../headersGenerator';

/**
 * Function used to get the places controlled by the user
 * @param token The barer token of the user
 * @returns The list of controlled places
 */
export const getControlledPlace = async (token: string): Promise<Array<Place>> => {
	try {
		const response: AxiosResponse<ResponseManager<Array<Place>>> = await axios.get(PLACE_CONTROLLED_URL, headerGenerator(token));
		return response.data.body;
	} catch (error: any) {
		if (error.response) {
			if (error.response.status === 401) {
				throw UNAUTHORIZED;
			} else {
				throw SERVER_ERROR;
			}
		} else {
			throw NETWORK_ERROR;
		}
	}
};

/**
 * Function used to recover all place types on server
 * @returns An array of place type
 */
export const get_types = async (): Promise<Array<PlaceType>> => {
	try {
		const response: AxiosResponse<Array<PlaceType>> = await axios.get(
			`${TYPE_URL}?client_id=${process.env.REACT_APP_CONTENT_CLIENT_ID}&client_secret=${process.env.REACT_APP_CONTENT_CLIENT_SECRET}`
		);
		return response.data;
	} catch (error: any) {
		if (error.response) {
			if (error.response.status === 401) {
				throw UNAUTHORIZED;
			} else {
				throw SERVER_ERROR;
			}
		} else {
			throw NETWORK_ERROR;
		}
	}
};

/**
 * Function used to crate a place
 * @param authToken The user barer token
 * @param name The place name
 * @param address The place address
 * @param description The place description
 * @param type_id The place type id
 * @param website The place website
 * @param avatar The place logo
 * @returns The place
 */
export const create_place = async (
	authToken: string,
	name: string,
	address: string,
	description: string,
	type_id: string,
	website: string,
	avatar?: string
): Promise<Place> => {
	try {
		const formData: FormData = new FormData();
		formData.append('name', name);
		formData.append('address', address);
		formData.append('description', description);
		formData.append('type', type_id);
		formData.append('website', website.startsWith('http') ? website : `https://${website}`);
		if (avatar) {
			formData.append('avatar', dataURLtoFile(avatar, `logo-${name}`));
		}
		const response: AxiosResponse<ResponseManager<Place>> = await axios.post(PLACE_CREATE_URL, formData, headerGenerator(authToken));
		return response.data.body;
	} catch (error: any) {
		if (error.response) {
			if (error.response.status === 401) {
				throw UNAUTHORIZED;
			} else {
				if (error.response.data.errors) {
					if (error.response.data.errors.avatar) {
						throw AVATAR_NOT_VALID;
					} else if (error.response.data.errors.website) {
						throw WEBSITE_NOT_VALID;
					}
				}

				throw SERVER_ERROR;
			}
		} else {
			throw NETWORK_ERROR;
		}
	}
};

/**
 * Function used to update a place
 * @param authToken The user barer token
 * @param place The current place
 * @param name The new place name
 * @param address The new place address
 * @param description The new place description
 * @param type_id The new place type id
 * @param website The new place website
 * @param avatar The new place logo
 * @returns If the place has been updated
 */
export const update_place = async (
	authToken: string,
	place: Place,
	name?: string,
	address?: string,
	description?: string,
	type_id?: string,
	website?: string,
	avatar?: string
): Promise<Boolean> => {
	//Check if we have modifications

	if (name || address || description || type_id || website || avatar) {
		const formData = new FormData();
		//Check if we have update
		let goUpdate = false;
		if (name && name !== place.name) {
			formData.append('name', name);
			goUpdate = true;
		}
		if (address && address !== place.address) {
			formData.append('address', address);
			goUpdate = true;
		}
		if (description && description !== place.description) {
			formData.append('description', description);
			goUpdate = true;
		}
		if (type_id && type_id !== place.type.id) {
			formData.append('type', type_id);
			goUpdate = true;
		}
		if (website && website !== place.website) {
			formData.append('website', website.startsWith('http') ? website : `https://${website}`);
			goUpdate = true;
		}
		if (avatar && avatar !== place.avatar) {
			formData.append('avatar', dataURLtoFile(avatar, `logo-${name}`));
			goUpdate = true;
		}
		if (goUpdate) {
			//Send the update
			try {
				await axios.post(`${PLACE_UPDATE_URL}/${place.id}`, formData, headerGenerator(authToken));
				return true;
			} catch (error: any) {
				if (error.response) {
					if (error.response.status === 401) {
						throw UNAUTHORIZED;
					} else {
						if (error.response.data.errors) {
							if (error.response.data.errors.avatar) {
								throw AVATAR_NOT_VALID;
							} else if (error.response.data.errors.website) {
								throw WEBSITE_NOT_VALID;
							}
						}
						throw SERVER_ERROR;
					}
				}
				throw NETWORK_ERROR;
			}
		} else {
			//No update
			throw NO_MODIFICATIONS;
		}
	} else {
		throw NO_MODIFICATIONS;
	}
};

/**
 * Function used to delete a place
 * @param authToken The user barer token
 * @param placeId The place ID
 * @returns If operation is done or an error string code
 */
export const delete_place = async (authToken: string, placeId: string): Promise<Boolean> => {
	try {
		await axios.delete(`${PLACE_URL}/${placeId}`, headerGenerator(authToken));
		return true;
	} catch (error: any) {
		if (error.response) {
			if (error.response.status === 401) {
				throw UNAUTHORIZED;
			} else {
				throw SERVER_ERROR;
			}
		} else {
			throw NETWORK_ERROR;
		}
	}
};

/**
 * Function used to get a specific place from his ID
 * @param authToken The user barer token
 * @param placeId The place id
 * @returns The place
 */
export const get_place = async (placeId: string, authToken?: string): Promise<Place> => {
	try {
		const response: AxiosResponse<ResponseManager<Place>> = await axios.get(
			`${PLACE_URL}/${placeId}?client_id=${process.env.REACT_APP_CONTENT_CLIENT_ID}&client_secret=${process.env.REACT_APP_CONTENT_CLIENT_SECRET}`,
			headerGenerator(authToken)
		);
		return response.data.body;
	} catch (error: any) {
		if (error.response) {
			if (error.response.status === 401) {
				throw UNAUTHORIZED;
			} else if (error.response.status === 404) {
				throw PLACE_NOT_FOUND;
			} else {
				throw SERVER_ERROR;
			}
		} else {
			throw NETWORK_ERROR;
		}
	}
};

/**
 * Function used to get all admin of a place
 * @param authToken The user barer token
 * @param placeId The place ID
 * @returns A list of users
 */
export const get_place_admin = async (authToken: string, placeId: string): Promise<Array<User>> => {
	try {
		const response: AxiosResponse<ResponseManager<Array<User>>> = await axios.get(`${PLACE_ADMIN_URL}/${placeId}`, headerGenerator(authToken));
		return response.data.body;
	} catch (error: any) {
		if (error.response) {
			if (error.response.status === 403) {
				throw UNAUTHORIZED;
			} else if (error.response.status === 404) {
				throw PLACE_NOT_FOUND;
			} else {
				throw SERVER_ERROR;
			}
		} else {
			throw NETWORK_ERROR;
		}
	}
};

/**
 *
 * @param authToken The user Barer token
 * @param placeId The place page ID
 * @param maxDate The maximum date to filter the results
 * @param page The current page
 * @returns A pagination of posts
 */
export const get_page_post = async (placeId: string, maxDate: Date, page: number = 1): Promise<Pagination<Post>> => {
	try {
		// Format the date to UTC timezone
		const month = maxDate.getUTCMonth() + 1;
		const day = maxDate.getUTCDate();
		const year = maxDate.getUTCFullYear();
		const hours = maxDate.getUTCHours();
		const minutes = maxDate.getUTCMinutes();
		const seconds = maxDate.getUTCSeconds();

		const formattedDate: String = `${year.toString()}-${month < 10 ? `0${month.toString()}` : month.toString()}-${
			day < 10 ? `0${day.toString()}` : day.toString()
		} ${hours < 10 ? `0${hours.toString()}` : hours.toString()}:${minutes < 10 ? `0${minutes.toString()}` : minutes.toString()}:${
			seconds < 10 ? `0${seconds.toString()}` : seconds.toString()
		}`;

		const response: AxiosResponse<Pagination<Post>> = await axios.get(
			`${POST_URL}/${placeId}?client_id=${process.env.REACT_APP_CONTENT_CLIENT_ID}&client_secret=${process.env.REACT_APP_CONTENT_CLIENT_SECRET}&date=${formattedDate}&page=${page}`
		);
		return response.data;
	} catch (error: any) {
		if (error.response) {
			if (error.response.status === 401) {
				throw UNAUTHORIZED;
			} else if (error.response.status === 404) {
				throw PLACE_NOT_FOUND;
			} else {
				throw SERVER_ERROR;
			}
		} else {
			throw NETWORK_ERROR;
		}
	}
};

/**
 * Function used to add an admin to a place
 * @param authToken The user barer token
 * @param placeId The place ID
 * @param email The user email of the future admin
 * @returns If operation is done
 */
export const add_admin = async (authToken: string, placeId: string, email: string): Promise<Boolean> => {
	try {
		await axios.put(`${PLACE_USER_URL}/${placeId}?email=${email}`, {}, headerGenerator(authToken));
		return true;
	} catch (error: any) {
		if (error.response) {
			if (error.response.status === 401) {
				throw UNAUTHORIZED;
			} else if (error.response.status === 404) {
				throw PLACE_NOT_FOUND;
			} else if (error.response.status === 422) {
				throw USER_NOT_FOUND;
			} else if (error.response.status === 409) {
				throw USER_ALREADY_ADMIN;
			} else {
				throw SERVER_ERROR;
			}
		} else {
			throw NETWORK_ERROR;
		}
	}
};

/**
 * Function used to remove an admin from a place
 * @param authToken The user barer token
 * @param placeId The place ID
 * @param userId The user ID to remove
 * @returns
 */
export const delete_admin = async (authToken: string, placeId: string, userId: string): Promise<Boolean> => {
	try {
		await axios.delete(`${PLACE_USER_URL}/${placeId}?user=${userId}`, headerGenerator(authToken));
		return true;
	} catch (error: any) {
		if (error.response) {
			if (error.response.status === 401) {
				throw UNAUTHORIZED;
			} else if (error.response.status === 404) {
				throw PLACE_NOT_FOUND;
			} else if (error.response.status === 422) {
				throw USER_NOT_FOUND;
			} else if (error.response.status === 409) {
				throw USER_NOT_ADMIN;
			} else {
				throw SERVER_ERROR;
			}
		} else {
			throw NETWORK_ERROR;
		}
	}
};

/**
 * Function used to send a post to a place
 * @param authToken The user barer token
 * @param place_id The place id
 * @param post The post to add
 * @returns If the post has been added
 */
export const send_place_post = async (authToken: string, place_id: string, post: string): Promise<boolean> => {
	try {
		const formData: FormData = new FormData();
		formData.append('content', post);
		await axios.post(`${POST_CREATE_URL}/${place_id}`, formData, headerGenerator(authToken));
		return true;
	} catch (error: any) {
		if (error.response) {
			if (error.response.status === 401) {
				throw UNAUTHORIZED;
			} else if (error.response.status === 403) {
				throw UNAUTHORIZED;
			} else {
				throw SERVER_ERROR;
			}
		} else {
			throw NETWORK_ERROR;
		}
	}
};

/**
 * Delete a post
 * @param authToken The user barer token
 * @param post_id The post ID
 * @returns Id operation is done
 */
export const delete_place_post = async (authToken: string, post_id: string): Promise<boolean> => {
	try {
		await axios.delete(`${POST_URL}/${post_id}`, headerGenerator(authToken));
		return true;
	} catch (error: any) {
		if (error.response) {
			if (error.response.status === 401) {
				throw UNAUTHORIZED;
			} else if (error.response.status === 403) {
				throw UNAUTHORIZED;
			} else {
				throw SERVER_ERROR;
			}
		} else {
			throw NETWORK_ERROR;
		}
	}
};

/**
 * A function used to search places by name
 * @param query The search query
 * @param page The page desired
 * @param type The type of place
 * @returns A pagination of places
 */
export const search_place_by_name = async (query: string, page: number, type?: string): Promise<Pagination<Place>> => {
	try {
		const result: AxiosResponse<Pagination<Place>> = await axios.get(
			`${PLACE_SEARCH_URL}?client_id=${process.env.REACT_APP_CONTENT_CLIENT_ID}&client_secret=${
				process.env.REACT_APP_CONTENT_CLIENT_SECRET
			}&query=${query}&page=${page}${type !== undefined && type !== '' ? `&type=${type}` : ''}`
		);
		return result.data;
	} catch (error: any) {
		if (error.response) {
			if (error.response.status === 404) {
				throw PLACE_NOT_FOUND;
			} else {
				throw SERVER_ERROR;
			}
		} else {
			throw NETWORK_ERROR;
		}
	}
};

/**
 * Function used to start following a place
 * @param authToken The barer token of the user
 * @param place_id The place ID
 * @returns If the user has followed the place
 */
export const follow_place = async (authToken: string, place_id: string): Promise<boolean> => {
	try {
		await axios.post(`${PLACE_FOLLOW_URL}/${place_id}`, {}, headerGenerator(authToken));
		return true;
	} catch (error: any) {
		if (error.response) {
			if (error.response.status === 401) {
				throw UNAUTHORIZED;
			} else if (error.response.status === 404) {
				throw PLACE_NOT_FOUND;
			} else if (error.response.status === 409) {
				throw PLACE_ALREADY_FOLLOWED;
			} else {
				throw SERVER_ERROR;
			}
		} else {
			throw NETWORK_ERROR;
		}
	}
};

/**
 * A function used to unfollow a place
 * @param authToken The user barer token
 * @param place_id The place ID
 * @returns If the user has unfollowed the place
 */
export const un_follow_place = async (authToken: string, place_id: string): Promise<boolean> => {
	try {
		await axios.delete(`${PLACE_FOLLOW_URL}/${place_id}`, headerGenerator(authToken));
		return true;
	} catch (error: any) {
		if (error.response) {
			if (error.response.status === 401) {
				throw UNAUTHORIZED;
			} else if (error.response.status === 404) {
				throw PLACE_NOT_FOUND;
			} else if (error.response.status === 409) {
				throw PLACE_NOT_FOLLOWED;
			} else {
				throw SERVER_ERROR;
			}
		} else {
			throw NETWORK_ERROR;
		}
	}
};

/**
 * A function used to recommend a place
 * @param authToken The user barer token
 * @param place_id The place ID
 * @returns If the user has recommended the place
 */
export const recommend_place = async (authToken: string, place_id: string): Promise<boolean> => {
	try {
		await axios.post(`${PLACE_RECOMMENDATION_URL}/${place_id}`, {}, headerGenerator(authToken));
		return true;
	} catch (error: any) {
		if (error.response) {
			if (error.response.status === 401) {
				throw UNAUTHORIZED;
			} else if (error.response.status === 404) {
				throw PLACE_NOT_FOUND;
			} else if (error.response.status === 409) {
				throw PLACE_ALREADY_RECOMMENDED;
			} else {
				throw SERVER_ERROR;
			}
		} else {
			throw NETWORK_ERROR;
		}
	}
};

/**
 * A function used to unrecommend a place
 * @param authToken The user barer token
 * @param place_id The place ID
 * @returns If the user has unrecommended the place
 */
export const un_recommend_place = async (authToken: string, place_id: string): Promise<boolean> => {
	try {
		await axios.delete(`${PLACE_RECOMMENDATION_URL}/${place_id}`, headerGenerator(authToken));
		return true;
	} catch (error: any) {
		if (error.response) {
			if (error.response.status === 401) {
				throw UNAUTHORIZED;
			} else if (error.response.status === 404) {
				throw PLACE_NOT_FOUND;
			} else if (error.response.status === 409) {
				throw PLACE_NOT_RECOMMENDED;
			} else {
				throw SERVER_ERROR;
			}
		} else {
			throw NETWORK_ERROR;
		}
	}
};

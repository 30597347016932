import { Link } from 'react-router-dom';

interface PageLinkProps {
	id: string;
	name: string;
	logo: string;
}

const PageLink = (props: PageLinkProps) => {
	return (
		<Link to={`/admin/place/${props.id}`} className='page-link'>
			<figure className='page-link--logo'>
				<img src={props.logo} alt='' aria-hidden='true' />
			</figure>
			<p className='page-link--name'>{props.name}</p>
		</Link>
	);
};

export default PageLink;

import { useState } from 'react';
import * as AccordionItemsProps from '../tools/models/AccordionItem';
import AccordionItem from './AccordionItem';

interface AccordionProps {
	items: Array<AccordionItemsProps.ItemList>;
}

const Accordion = (props: AccordionProps) => {
	const [current, setCurrent] = useState<number | null>(null);

	const handleToggle = (index: number) => {
		if (current === index) {
			setCurrent(null);
		} else {
			setCurrent(index);
		}
	};

	return (
		<ul className='accordion'>
			{props.items.map((item, index) => (
				<AccordionItem
					{...item}
					key={index}
					onToggle={() => {
						handleToggle(index);
					}}
					active={current === index}
				/>
			))}
		</ul>
	);
};

export default Accordion;

import useMutationObserver from '@rooks/use-mutation-observer';
import { useEffect, useRef, useState } from 'react';
import * as AccordionItemProps from '../tools/models/AccordionItem';

const AccordionItem = (props: AccordionItemProps.default) => {
	const contentRef = useRef<HTMLDivElement>(null);
	const [contentHeight, setContentHeight] = useState<number>(0);

	useMutationObserver(
		contentRef,
		() => {
			if (contentRef.current) {
				setContentHeight(contentRef.current.scrollHeight);
			}
		},
		{ attributes: true, childList: true, subtree: true }
	);

	useEffect(() => {
		if (contentRef.current) {
			setContentHeight(contentRef.current.scrollHeight);
		}
	}, [contentRef]);

	return (
		<li className='accordion--item'>
			<button className={`accordion--item-toggle accordion--item-toggle-${props.active ? 'active' : 'inactive'}`} onClick={props.onToggle}>
				<span className='accordion--item-start'>
					<span className='accordion--item-title'>{props.title}</span>
					{props.notice ? <span className='accordion--item-notice'>{props.notice}</span> : ''}
				</span>
				<span className='accordion--item-end'>
					<i className='fa-solid fa-chevron-down'></i>
				</span>
			</button>
			<div
				ref={contentRef}
				style={props.active ? { height: contentHeight } : { height: 0 }}
				className={`accordion--item-content accordion--item-content-${props.active ? 'open' : 'close'}`}>
				<div className='accordion--item-content-space'>{props.content}</div>
			</div>
		</li>
	);
};

export default AccordionItem;

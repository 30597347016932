import { Field } from 'formik';

interface SwitchProps {
	id: string;
	name: string;
	label: string;
}

const Switch = (props: SwitchProps) => {
	return (
		<div className='switch'>
			<Field name={props.name} id={props.id} type='checkbox' className='switch--input' />
			<label htmlFor={props.id} className='switch--label'>
				<span className='switch--label-text'>{props.label}</span>
				<span className='switch--container'>
					<span className='switch--cursor'></span>
				</span>
			</label>
		</div>
	);
};

export default Switch;

import PlacePost, { PlacePostProps } from './PlacePost';

interface PlacePostListProps {
	posts: Array<PlacePostProps>;
	canDelete: boolean;
}

const PlacePostList = (props: PlacePostListProps) => {
	return (
		<ul className='place-post--list'>
			{props.posts.map((post) => (
				<li className='place-post--list-item' key={post.id}>
					<PlacePost {...post} canDelete={props.canDelete} />
				</li>
			))}
		</ul>
	);
};

export default PlacePostList;

import Header from '../components/Header';
import Page from '../components/Page';
import headerImage from '../assets/images/mockup/overview-SocialOMatic-phones.png';
import TextWithImage from '../components/TextWithImage';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';
import Section from '../components/Section';

const About = () => {
	return (
		<Page title='À propos' pageClass='about'>
			<Header title="L'histoire du Social O Matic de ses origines jusqu'à aujourd’hui !" image={headerImage} />
			<TextWithImage
				content={
					<>
						<p>
							Auparavant juste une idée, le Social O Matic est rapidement devenu une évidence pour son créateur, un étudiant en développement pour son projet de
							diplôme.
						</p>
						<p>
							Ne souhaitant pas développer juste créer une application sans but, il a plutôt décidé de trouver une solution pour améliorer son propre quotidien.
						</p>
						<p>
							Sortant souvent avec des amis, il trouvait fastidieux d’organiser des soirées par messages, dans plusieurs groupes, avec plusieurs personnes…
							<br />
							C’est ainsi qu’il a eu l’idée de créer le Social O Matic, afin de s’organiser lui-même mais également ses amis.
						</p>
					</>
				}
				side={
					<Lottie
						options={{
							animationData: require('../assets/lottie/illustrations_social_portable.json'),
							loop: true,
						}}
					/>
				}
				large={false}
				reverse={false}
			/>
			<Section color='white'>
				<div className='col-xs-4 col-sm-12 section--body'>
					<h3 className='section--title'>Le Social O Matic pour tous !</h3>
					<p className='section--text'>
						Cette application n'est pas seulement destinée à des particuliers, les professionnels peuvent également s'inscrire afin de promouvoir leurs
						établissements et les événements à leurs clients à proximité !
					</p>
					<Link to='/pro' className='button--black'>
						En savoir plus
					</Link>
				</div>
			</Section>
			<TextWithImage
				title='Local avant tout !'
				content={
					<>
						<p>Pensé, designé et développé en Suisse, nous soutenons tout ce qui est local jusqu’à notre hébergeur, également en Suisse !</p>
						<p>
							Toutes vous données sont stockées sur des serveurs sécurisés et sont supprimées dès que nous en avons plus besoin ou lorsque vous supprimez votre
							compte.
						</p>
						<Link to='/confidentiality' className='button--primary'>
							En savoir plus sur la sécurité
						</Link>
					</>
				}
				side={
					<Lottie
						options={{
							animationData: require('../assets/lottie/illustrations_social_verres.json'),
							loop: true,
						}}
					/>
				}
				large={false}
				reverse={false}
			/>
		</Page>
	);
};

export default About;

import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Loader from '../../components/Loader';
import Notice from '../../components/Notice';
import Switch from '../../components/Switch';
import { useAuth } from '../../context/AuthContext';
import { update_user } from '../../tools/server/auth/auth';
import { getCurrentUser } from '../../tools/server/content/user';

const Notifications = () => {
	const { authState } = useAuth();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [initialValues, setInitialValues] = useState<any>({
		addFiends: false,
		inviteEvents: false,
		editEvent: false,
	});

	useEffect(() => {
		loadData();
		// eslint-disable-next-line
	}, []);

	const loadData = () => {
		setIsLoading(true);
		getCurrentUser(authState.token)
			.then((response) => {
				if (response !== null) {
					setInitialValues({
						addFiends: response.push_add_friend === 1 ? true : false,
						inviteEvents: response.push_invite === 1 ? true : false,
						editEvent: response.push_invite_update === 1 ? true : false,
					});
					setIsLoading(false);
				}
			})
			.catch(() => {
				setErrorMessage('Une erreur est survenue lors de la récupération des données. Veuillez réessayer.');
				setIsLoading(false);
			});
	};

	const handleSubmit = async (values: any) => {
		setIsLoading(true);
		setErrorMessage(null);
		try {
			await update_user(
				authState.token,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				values.addFiends,
				values.inviteEvents,
				values.editEvent
			);
			loadData();
		} catch (error) {
			setErrorMessage('Une erreur est survenue lors de la mise à jour. Veuillez réessayer.');
			setIsLoading(false);
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<Formik
			onSubmit={(values) => {
				handleSubmit(values);
			}}
			initialValues={initialValues}>
			<Form>
				{errorMessage && (
					<div className='form-group'>
						<Notice type='error'>{errorMessage}</Notice>
					</div>
				)}
				<div className='form-group'>
					<Switch name='addFiends' id='addFiends' label="Ajout d'amis" />
				</div>
				<div className='form-group'>
					<Switch name='inviteEvents' id='inviteEvents' label='Invitations à des événements' />
				</div>
				<div className='form-group'>
					<Switch name='editEvent' id='editEvent' label='Participation à des événements' />
				</div>
				<div className='form-group form-group-submit'>
					<button type='submit' className='button--black'>
						Sauvegarder
					</button>
				</div>
			</Form>
		</Formik>
	);
};

export default Notifications;

import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ImageUpload from '../../../components/ImageUpload';
import Notice from '../../../components/Notice';
import Page from '../../../components/Page';
import RestrictedArea from '../../../components/RestrictedArea';
import { useAuth } from '../../../context/AuthContext';
import { Place } from '../../../tools/models/place';
import { PlaceType } from '../../../tools/models/type';
import { add_admin, create_place, delete_admin, delete_place, get_place, get_place_admin, get_types, update_place } from '../../../tools/server/content/place';
import {
	AVATAR_NOT_VALID,
	NO_MODIFICATIONS,
	PLACE_NOT_FOUND,
	UNAUTHORIZED,
	USER_ALREADY_ADMIN,
	USER_NOT_ADMIN,
	USER_NOT_FOUND,
	WEBSITE_NOT_VALID,
} from '../../../tools/server/errors';
import * as Yup from 'yup';
import Loader from '../../../components/Loader';
import { User } from '../../../tools/models/user';

interface placeForm {
	name: string;
	address: string;
	description: string;
	website: string;
	type: string;
	logo: string;
}

const PlaceEditor = () => {
	const { placeId } = useParams();
	const { authState } = useAuth();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [adminLoading, setAdminLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const [adminError, setAdminError] = useState<string | null>(null);
	const [placeTypes, setPlaceTypes] = useState<Array<PlaceType>>([]);
	const [place, setPlace] = useState<null | Place>(null);
	const [admins, setAdmins] = useState<Array<User>>([]);
	const [initialValues, setInitialValues] = useState<placeForm>({
		name: '',
		address: '',
		description: '',
		website: '',
		type: '',
		logo: '',
	});
	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Le nom est obligatoire'),
		address: Yup.string().required("L'adresse est obligatoire"),
		description: Yup.string().required('La description est obligatoire'),
		website: Yup.string().required('Le site internet est obligatoire').url("L'URL n'est pas valide"),
		type: Yup.string()
			.required('Le type est obligatoire')
			.oneOf(
				placeTypes.map((type) => type.id),
				"Le type n'est pas valide"
			),
	});

	const new_place = (values: any) => {
		setIsLoading(true);
		setError(null);

		create_place(authState.token, values.name, values.address, values.description, values.type, values.website, values.logo === '' ? null : values.logo)
			.then((place: Place) => {
				navigate('/admin/place/' + place.id);
				setIsLoading(false);
			})
			.catch((error: string) => {
				switch (error) {
					case AVATAR_NOT_VALID: {
						setError("L'image n'est pas valide. veuillez vérifier que le format correspond à un fichier .jpg, .jpeg ou .png");
						break;
					}
					case UNAUTHORIZED: {
						setError("Vous n'êtes pas autorisé à effectuer cette action");
						break;
					}
					case WEBSITE_NOT_VALID: {
						setError("L'URL n'est pas valide. Pensez à bien ajouter le http:// ou https://");
						break;
					}
					default: {
						setError('Une erreur est survenue');
					}
				}
				setIsLoading(false);
			});
	};

	const update = (values: any) => {
		setIsLoading(true);
		setError(null);

		update_place(authState.token, place!, values.name, values.address, values.description, values.type, values.website, values.logo === '' ? null : values.logo)
			.then(() => {
				setIsLoading(false);
				navigate('/admin/place/' + place!.id);
			})
			.catch((error: string) => {
				switch (error) {
					case AVATAR_NOT_VALID: {
						setError("L'image n'est pas valide. veuillez vérifier que le format correspond à un fichier .jpg, .jpeg ou .png");
						break;
					}
					case UNAUTHORIZED: {
						setError("Vous n'êtes pas autorisé à effectuer cette action");
						break;
					}
					case WEBSITE_NOT_VALID: {
						setError("L'URL n'est pas valide. Pensez à bien ajouter le http:// ou https://");
						break;
					}
					case NO_MODIFICATIONS: {
						setError("Aucune modification n'a été effectuée");
						break;
					}
					default: {
						setError('Une erreur est survenue');
						break;
					}
				}
				setIsLoading(false);
			});
	};

	const deletePage = () => {
		setError(null);
		setIsLoading(true);

		delete_place(authState.token, place!.id)
			.then(() => {
				navigate('/my-account');
				setIsLoading(false);
			})
			.catch((error: string) => {
				switch (error) {
					case UNAUTHORIZED: {
						setError("Vous n'êtes pas autorisé à effectuer cette action");
						break;
					}
					default: {
						setError('Une erreur est survenue');
						break;
					}
				}
				setIsLoading(false);
			});
	};

	const boot = async () => {
		setIsLoading(true);
		setError(null);

		//Get types
		try {
			const types = await get_types();
			setPlaceTypes(types);
		} catch (error: any) {
			setError('Une erreur est survenue. Merci de réessayer plus tard');
		}

		if (placeId) {
			//Get place current details
			try {
				const response = await get_place(placeId);
				setPlace(response);
				setInitialValues({
					name: response.name,
					address: response.address,
					description: response.description,
					website: response.website ? response.website : '',
					logo: '',
					type: response.type.id,
				});

				//Get admins
				await get_admin();
			} catch (error: any) {
				switch (error) {
					case PLACE_NOT_FOUND: {
						navigate('place-not-found');
						break;
					}
					default: {
						setError('Une erreur est survenue. Merci de réessayer plus tard.');
						break;
					}
				}
			}
		}
		setIsLoading(false);
	};

	const get_admin = async () => {
		setAdminLoading(true);
		setAdminError(null);
		const users = await get_place_admin(authState.token, placeId!);
		setAdmins(users);
		setAdminLoading(false);
	};

	useEffect(() => {
		boot();
		// eslint-disable-next-line
	}, [placeId]);

	if (isLoading) {
		return (
			<div className='page page-place-editor'>
				<div className='container'>
					<Loader />
				</div>
			</div>
		);
	}

	return (
		<RestrictedArea needAuth={true}>
			<Page title={placeId ? 'Modifier Social O Matic' : 'Créer une page'} pageClass='place-editor'>
				<div className='container'>
					<section className='row page-place-editor--header'>
						<div className='col-xs-4 col-sm-12'>
							<h1 className='page--title'>{placeId ? 'Modifier la page Social O Matic' : 'Créer une nouvelle page'}</h1>
						</div>
					</section>
					<section className='row page-place-editor--body'>
						<div className='col-xs-4 col-sm-12 col-md-6'>
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={(values) => {
									setInitialValues({
										name: values.name,
										type: values.type,
										address: values.address,
										description: values.description,
										website: values.website,
										logo: values.logo,
									});
									if (placeId) {
										//EDIT mode
										update(values);
									} else {
										//Create a new place
										new_place(values);
									}
								}}>
								<Form>
									{error && (
										<div className='form-group'>
											<Notice type='error'>{error}</Notice>
										</div>
									)}
									<div className='form-group'>
										<label htmlFor='name'>Nom de l'établissement</label>
										<Field type='text' name='name' id='name' />
										<ErrorMessage name='name' component='div' className='form-error' />
									</div>
									<div className='form-group'>
										<label htmlFor='type'>Classification de l'établissement</label>
										<Field as='select' name='type' id='type'>
											<option value=''>Choisissez une classification</option>
											{placeTypes.map((type) => (
												<option key={type.id} value={type.id}>
													{type.name}
												</option>
											))}
										</Field>
										<ErrorMessage name='type' component='div' className='form-error' />
									</div>
									<div className='form-group'>
										<label htmlFor='description'>Description</label>
										<Field as='textarea' name='description' id='description' />
										<ErrorMessage name='description' component='div' className='form-error' />
									</div>
									<div className='form-group'>
										<label htmlFor='address'>Adresse de l'établissement</label>
										<Field type='text' name='address' id='address' />
										<ErrorMessage name='address' component='div' className='form-error' />
									</div>
									<div className='form-group'>
										<label htmlFor='website'>Site web</label>
										<Field type='text' name='website' id='website' />
										<ErrorMessage name='website' component='div' className='form-error' />
									</div>
									<div className='form-group'>
										<ImageUpload label='Logo de votre établissement' id='logo' name='logo' currentImage={place === null ? null : place.avatar} />
									</div>
									<div className='form-group form-group-submit'>
										<button className='button--black' type='submit'>
											{placeId ? 'Enregistrer les modifications' : 'Créer la page'}
										</button>
									</div>
									{place && (
										<div className='form-group'>
											<button
												className='button--black'
												type='button'
												onClick={() => {
													if (window.confirm('Êtes-vous sûr de vouloir supprimer cette page ?')) {
														deletePage();
													}
												}}>
												Supprimer la page
											</button>
										</div>
									)}
								</Form>
							</Formik>
						</div>
						<div className='col-xs-4 col-sm-12 col-md-6 page-place-editor--admin'>
							<h2 className='page--subtitle'>Administrateurs de la page</h2>
							<p className='page-place-editor--admin-description'>
								Les administrateurs peuvent envoyer des posts, ajouter/retirer d'autres administrateurs ou encore supprimer la page.
							</p>
							{adminError && <Notice type='error'>{adminError}</Notice>}
							{placeId ? (
								<Formik
									initialValues={{
										email: '',
									}}
									validationSchema={Yup.object().shape({
										email: Yup.string().email('Adresse email invalide').required('Obligatoire'),
									})}
									onSubmit={(values) => {
										setAdminLoading(true);
										setAdminError(null);
										add_admin(authState.token, placeId, values.email)
											.then(() => {
												get_admin();
											})
											.catch((error: string) => {
												switch (error) {
													case USER_NOT_FOUND: {
														setAdminError("Cet utilisateur n'existe pas");
														break;
													}
													case UNAUTHORIZED: {
														setAdminError("Vous n'êtes pas autorisé à effectuer cette action");
														break;
													}
													case USER_ALREADY_ADMIN: {
														setAdminError('Cet utilisateur est déjà administrateur');
														break;
													}
													default: {
														setAdminError('Une erreur est survenue');
														break;
													}
												}
												setAdminLoading(false);
											});
									}}>
									<Form>
										<div className='form-group'>
											<label htmlFor='email'>E-mail du compte</label>
											<Field type='email' name='email' id='email' />
											<ErrorMessage name='email' component='div' className='form-error' />
										</div>
										<div className='form-group form-group-submit'>
											<button className='button--black' type='submit'>
												Ajouter
											</button>
										</div>
									</Form>
								</Formik>
							) : (
								<Notice type='info'>
									<p>Une fois votre page créée, vous pourrez ajouter des administrateurs</p>
								</Notice>
							)}
							{adminLoading ? (
								<Loader />
							) : (
								<ul className='page-place-editor--admin-list'>
									{place && admins.length === 0 ? (
										<Notice type='info'>
											<p>Aucun administrateur pour le moment</p>
										</Notice>
									) : (
										admins.map((admin) => (
											<li className='page-place-editor--admin-item' key={admin.id}>
												<p className='page-place-editor--admin-item-details'>
													<span className='page-place-editor--admin-item-name'>
														{admin.firstName} {admin.lastName}
													</span>
													<span className='page-place-editor--admin-item-email'>{admin.email}</span>
												</p>
												{admin.id !== authState.userId && (
													<button
														className='button--black'
														onClick={() => {
															if (window.confirm('Êtes-vous sûr de vouloir retirer cet administrateur ?')) {
																setAdminLoading(true);
																setAdminError(null);
																delete_admin(authState.token, place!.id, admin.id)
																	.then(() => {
																		get_admin();
																	})
																	.catch((error: string) => {
																		switch (error) {
																			case UNAUTHORIZED: {
																				setAdminError("Vous n'êtes pas autorisé à effectuer cette action");
																				break;
																			}
																			case USER_NOT_ADMIN: {
																				setAdminError("Cet utilisateur n'est pas administrateur");
																				break;
																			}
																			default: {
																				setAdminError('Une erreur est survenue');
																				break;
																			}
																		}
																		setAdminLoading(false);
																	});
															}
														}}>
														Retirer cet administrateur
													</button>
												)}
											</li>
										))
									)}
								</ul>
							)}
						</div>
					</section>
				</div>
			</Page>
		</RestrictedArea>
	);
};

export default PlaceEditor;

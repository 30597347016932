import { useEffect } from 'react';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import RestrictedArea from '../../components/RestrictedArea';
import { usePasswordContext } from '../../context/PasswordContext';
import useWindowSize from '../../hooks/useWindowSize';
import ConfirmEmail from './steps/ConfirmEmail';
import RequestPassword from './steps/RequestPassword';
import SetNewPassword from './steps/SetNewPassword';

const ResetPasswordDispatch = () => {
	const { passwordState, passwordDispatch } = usePasswordContext();
	const navigate = useNavigate();
	const windowSize = useWindowSize();

	useEffect(() => {
		if (passwordState.step > 3) {
			passwordDispatch({ type: 'RESET' });
			navigate('/login');
		}
		// eslint-disable-next-line
	}, [passwordState]);

	return (
		<RestrictedArea needAuth={false}>
			<div className='password-reset-dispatcher' style={{ minHeight: windowSize.height }}>
				<div className='container'>
					<div className='row'>
						<div className='col-xs-4 col-sm-12 col-md-3 password-reset-dispatcher-animation'>
							<Lottie
								options={{
									animationData: require('../../assets/lottie/illustrations_social_verres.json'),
									loop: true,
								}}
							/>
						</div>
						<div className='col-xs-4 col-sm-12 col-md-1' />
						<div className='col-xs-4 col-sm-12 col-md-8 password-reset-dispatcher-views'>
							{passwordState.step === 1 ? (
								<RequestPassword />
							) : passwordState.step === 2 ? (
								<ConfirmEmail />
							) : passwordState.step === 3 ? (
								<SetNewPassword />
							) : (
								<></>
							)}
						</div>
					</div>
				</div>
			</div>
		</RestrictedArea>
	);
};

export default ResetPasswordDispatch;

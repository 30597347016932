//@ts-ignore
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { useEffect, useRef } from 'react';
import MapFeature, { MapFeatureCollection, MapFeaturePropertyId } from '../tools/models/MapFeature';

interface MapProps {
	lat: number;
	lng: number;
	zoom: number;
	mapFeatures?: MapFeatureCollection<MapFeaturePropertyId>;
}

const Map = (props: MapProps) => {
	const { lat, lng, zoom, mapFeatures } = props;
	mapboxgl.accessToken = process.env.REACT_APP_MAPBOX as string;
	const mapContainer = useRef<any>();
	const map = useRef<any>();

	//Boot
	useEffect(() => {
		if (map.current) return; // initialize map only once
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/borgo199/clcf03g0c004514nvkl3s3edq',
			center: [lng, lat],
			zoom: zoom,
			cooperativeGestures: true,
		});
	});

	//Load points
	useEffect(() => {
		if (mapFeatures && map.current) {
			map.current.on('load', () => {
				mapFeatures.features.forEach((feature: MapFeature<MapFeaturePropertyId>) => {
					const el = document.createElement('div');
					el.id = `marker-${feature.properties.id}`;
					el.className = 'marker';

					new mapboxgl.Marker(el, { offset: [0, -23] }).setLngLat(feature.geometry.coordinates).addTo(map.current);
				});
			});
		} else {
			return;
		}
	}, [mapFeatures, map]);

	return <div className='map' ref={mapContainer}></div>;
};

export default Map;

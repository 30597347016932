import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../../components/Loader';
import Map from '../../../components/Map';
import Page from '../../../components/Page';
import Section from '../../../components/Section';
import { Place } from '../../../tools/models/place';
import { follow_place, get_page_post, get_place, recommend_place, un_follow_place, un_recommend_place } from '../../../tools/server/content/place';
import { PLACE_NOT_FOUND } from '../../../tools/server/errors';
import PlaceHolder from '../../../assets/images/icons/placeholder-place-icon.svg';
import PlacePostList from '../../../components/PlacePostList';
import { Post } from '../../../tools/models/post';
import { PlacePostProps } from '../../../components/PlacePost';
import StatsList from '../../../components/StatsList';
import { useAuth } from '../../../context/AuthContext';
import Notice from '../../../components/Notice';
import PlaceDetailsEventsList from '../../../components/PlaceDetailsEventsList';
import useWindowSize from '../../../hooks/useWindowSize';

const PlaceDetail = () => {
	const { placeId } = useParams<{ placeId: string }>();
	const { authState } = useAuth();
	const { width } = useWindowSize();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [postLoading, setPostLoading] = useState<boolean>(false);
	const [pageTitle, setPageTitle] = useState<string>('Chargement...');
	const [place, setPlace] = useState<Place | null>(null);
	const [posts, setPosts] = useState<Array<PlacePostProps>>([]);
	const [date, setDate] = useState<Date>(new Date());
	const [postPage, setPostPage] = useState<number>(1);
	const [postError, setPostError] = useState<string | null>(null);
	const [postLoadMore, setPostLoadMore] = useState<boolean>(false);

	const boot = async () => {
		if (placeId) {
			//Reset values
			setIsLoading(true);
			setPostLoading(false);
			setPostPage(1);

			//Get place
			try {
				const place = await get_place(placeId, authState.token ? authState.token : undefined);
				setPlace(place);
				setPageTitle(place.name);
			} catch (error) {
				if (error === PLACE_NOT_FOUND) {
					setPageTitle('Lieu introuvable');
				} else {
					setPageTitle('Erreur');
					console.error(error);
				}
				return;
			}

			//Get posts
			try {
				setDate(new Date());
				const p = await get_page_post(placeId, new Date(), 1);
				if (p.data) {
					let preparedPosts: Array<PlacePostProps> = [];
					p.data.forEach((post: Post) => {
						preparedPosts.push({
							id: post.id,
							name: post.place.name,
							logo: post.place.avatar ? post.place.avatar : PlaceHolder,
							date: new Date(post.created_at ? post.created_at : ''),
							content: post.content,
						});
					});
					setPosts(preparedPosts);

					//Check if we can load more
					if (p.last_page <= p.current_page) {
						setPostLoadMore(false);
					} else {
						setPostLoadMore(true);
					}
					setPostPage(p.current_page);
				} else {
					setPosts([]);
				}
			} catch (error) {
				console.error(error);
				setPostError('Une erreur est survenue lors du chargement des publications. Veuillez réactualiser la page.');
				return;
			}

			//end of initialize
			return;
		}
	};

	const loadMorePosts = async () => {
		if (place) {
			setPostLoading(true);
			try {
				const newPosts = await get_page_post(place.id, date, postPage + 1);
				//Prepare new list
				if (newPosts.data) {
					let p = posts;
					newPosts.data.forEach((post: Post) => {
						p.push({
							id: post.id,
							name: post.place.name,
							logo: post.place.avatar ? post.place.avatar : PlaceHolder,
							date: new Date(post.created_at ? post.created_at : ''),
							content: post.content,
						});
					});
					setPosts(p);
				}
				//Check if we can load more
				if (newPosts.last_page <= newPosts.current_page) {
					setPostLoadMore(false);
				} else {
					setPostPage(newPosts.current_page);
					setPostLoadMore(true);
				}
			} catch (error) {
				console.error(error);
				setPostLoadMore(false);
			}
			setPostLoading(false);
		} else {
			setPostLoadMore(false);
		}
	};

	useEffect(() => {
		setIsLoading(true);
		boot().then(() => setIsLoading(false));
		// eslint-disable-next-line
	}, [placeId]);

	return (
		<Page pageClass='place-details' title={pageTitle}>
			{isLoading ? (
				<Loader />
			) : place ? (
				<Section className='page-place-details--public' color='default'>
					<div className='col-xs-4 col-sm-12 col-md-6 col-lg-4 page-place-details--main-information'>
						<div className='page-place-details--identity'>
							<img src={place.avatar ? place.avatar : PlaceHolder} alt={place.name} className='page-place-details--identity-logo' />
							<h1 className='page-place-details--identity-name page--title'>{place.name}</h1>
							<StatsList
								stats={[
									{ icon: 'thumbs-up', value: place.recommendations ? place.recommendations : 0 },
									{ icon: 'users', value: place.followers ? place.followers : 0 },
								]}
							/>
							<div className='page-place-details--identity-actions'>
								{authState.userId ? (
									<>
										<button
											className='button button--primary'
											onClick={() => {
												if (place.isFollowing) {
													//Un-follow
													setIsLoading(true);
													un_follow_place(authState.token, place.id).finally(() => {
														boot().then(() => setIsLoading(false));
													});
												} else {
													//Follow
													setIsLoading(true);
													follow_place(authState.token, place.id).finally(() => {
														boot().then(() => setIsLoading(false));
													});
												}
											}}>
											{place.isFollowing ? 'Vous suivez ce lieu' : 'Suivre ce lieu'}
										</button>
										<button
											className='button button--black'
											onClick={() => {
												if (place.isRecommending) {
													//Un-recommend
													setIsLoading(true);
													un_recommend_place(authState.token, place.id).finally(() => {
														boot().then(() => setIsLoading(false));
													});
												} else {
													//Recommend
													setIsLoading(true);
													recommend_place(authState.token, place.id).finally(() => {
														boot().then(() => setIsLoading(false));
													});
												}
											}}>
											{place.isFollowing ? 'Vous recommandez ce lieu' : 'Recommander ce lieu'}
										</button>
									</>
								) : (
									<p>
										<Link to='/my-account'>Connectez-vous</Link> ou <Link to='/register'>Inscrivez-vous</Link> pour pouvoir suivre ce lieu et profiter de toutes
										les fonctionnalités ici et sur notre application mobile.
									</p>
								)}
							</div>
						</div>
						<div className='page-place-details--localisation'>
							<Map
								lat={place.lat}
								lng={place.long}
								zoom={16}
								mapFeatures={{
									type: 'FeatureCollection',
									features: [
										{
											type: 'Feature',
											geometry: {
												type: 'Point',
												coordinates: [place.long, place.lat],
											},
											properties: {
												id: place.id,
											},
										},
									],
								}}
							/>
							<div className='page-place-details--localisation-text'>
								<p className='page-place-details--localisation-address'>{place.address}</p>
								<p className='page-place-details--localisation-description'>{place.description}</p>
								{place.website && (
									<a href={place.website} target='_blank' rel='noreferrer' className='page-place-details--localisation-website'>
										{place.website}
									</a>
								)}
							</div>
						</div>
						{width && width < 1200 && <PlaceDetailsEventsList placeId={place.id} />}
					</div>
					<div className='col-xs-4 col-sm-12 col-md-6 col-lg-4 page-place-details--posts'>
						{posts.length > 0 ? (
							<PlacePostList posts={posts} canDelete={false} />
						) : postError ? (
							<Notice type='error'>{postError}</Notice>
						) : (
							<Notice type='info'>Cette page n'as publié aucun post pour le moment</Notice>
						)}
						{postLoading ? (
							<Loader />
						) : (
							postLoadMore && (
								<button className='button button--primary' onClick={loadMorePosts}>
									Charger plus de posts
								</button>
							)
						)}
					</div>
					{width && width >= 1200 && (
						<aside className='col-xs-4 col-sm-12 col-md-6 col-lg-4 page-place-details--events'>
							<PlaceDetailsEventsList placeId={place.id} />
						</aside>
					)}
				</Section>
			) : (
				<></>
			)}
		</Page>
	);
};

export default PlaceDetail;

import { useState } from 'react';
import PlaceHolder from '../assets/images/icons/placeholder-place-icon.svg';
import { useAuth } from '../context/AuthContext';
import { dateGenerator } from '../tools/metaTools';
import { delete_place_post } from '../tools/server/content/place';
import Loader from './Loader';

export interface PlacePostProps {
	id: string;
	name: string;
	logo: string;
	date: Date;
	content: string;
	canDelete?: boolean;
}

const PlacePost = (props: PlacePostProps) => {
	const { authState } = useAuth();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isDeleted, setIsDeleted] = useState<boolean>(false);

	return (
		<article className='place-post'>
			<div className='place-post--header'>
				<figure className='place-post--logo'>
					<img src={props.logo === '' ? PlaceHolder : props.logo} alt={props.name} />
				</figure>
				<p className='place-post--name'>{props.name}</p>
				<p className='place-post--date'>{dateGenerator(props.date, true)}</p>
			</div>
			<div className='place-post--body'>
				{isDeleted ? <p className='place-post--content'>Ce poste a été supprimé</p> : <p className='place-post--content'>{props.content}</p>}
			</div>
			<div className='place-post--footer'>
				{isLoading ? (
					<Loader />
				) : (
					isDeleted === false &&
					props.canDelete && (
						<button
							className='place-post--delete'
							onClick={() => {
								if (window.confirm('Voulez-vous vraiment supprimer ce poste ?')) {
									setIsLoading(true);
									delete_place_post(authState.token, props.id)
										.then(() => {
											setIsLoading(false);
											setIsDeleted(true);
										})
										.catch(() => {
											setIsLoading(false);
										});
								}
							}}>
							Supprimer
						</button>
					)
				)}
			</div>
		</article>
	);
};

export default PlacePost;

interface NoticeProps {
	children?: React.ReactNode;
	type: 'success' | 'error' | 'info' | 'warning';
}

const Notice = (props: NoticeProps) => {
	return <div className={`notice notice--${props.type}`}>{props.children}</div>;
};

export default Notice;

import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import * as Yup from 'yup';
import Notice from '../../components/Notice';
import Loader from '../../components/Loader';
import { EMAIL_REJECTED } from '../../tools/server/errors';
import { update_user } from '../../tools/server/auth/auth';

const Email = () => {
	const { authState, authDispatch } = useAuth();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const validationSchema = Yup.object().shape({
		email: Yup.string().required('Obligatoire').email('Email invalide'),
	});

	const handleSubmit = async (values: any) => {
		setIsLoading(true);
		setErrorMessage(null);
		try {
			const response = await update_user(authState.token, undefined, undefined, undefined, values.email);
			if (response !== null) {
				const update = {
					type: 'RESTORE_SESSION',
					payload: {
						...authState,
						email: response.email,
						emailValidatedAt: response.email_verified_at,
					},
				};
				authDispatch(update);
			}
			setIsLoading(false);
		} catch (error: any) {
			if (error.email === EMAIL_REJECTED) {
				setErrorMessage('Cet email est déjà utilisé');
			} else {
				setErrorMessage('Une erreur est survenue lors de la mise à jour. Veuillez réessayer.');
			}
			setIsLoading(false);
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<Formik
			initialValues={{
				email: authState.email,
			}}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				handleSubmit(values);
			}}>
			<Form>
				{errorMessage && (
					<div className='form-group'>
						<Notice type='error'>{errorMessage}</Notice>
					</div>
				)}
				<div className='form-group'>
					<label htmlFor='email'>Email</label>
					<Field name='email' type='email' />
					<ErrorMessage name='email' component='div' className='invalid-feedback' />
				</div>
				<div className='form-group form-group-submit'>
					<button type='submit' className='button--black'>
						Sauvegarder
					</button>
				</div>
			</Form>
		</Formik>
	);
};

export default Email;

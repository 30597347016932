// MARK: - Server errors
export const SERVER_ERROR = 'Server Error';
export const SERVER_REJECTED = 'Server rejected';
export const EMAIL_REJECTED = 'Email rejected';
export const USERNAME_REJECTED = 'Username rejected';
export const BAD_CODE = 'Bad code';
export const CODE_NOT_VALID = 'Code not valid';
export const ALREADY_SENDED = 'Already sended';
export const UNAUTHORIZED = 'Unauthorized';

// MARK: - Connection error
export const NETWORK_ERROR = 'Network Error';

// MARK: - Types errors
export const MISSING_VALUES = 'Missing values';
export const EMAIL_NOT_VALID = 'Email is not valid';
export const PASSWORD_NOT_VALID = 'Password is not valid';
export const USERNAME_NOT_VALID = 'Username is not valid';
export const USERNAME_TOO_SHORT = 'Username is too short';
export const NAME_NOT_VALID = 'Name is not valid';
export const NAME_TOO_SHORT = 'Name is too short';
export const SURNAME_NOT_VALID = 'Surname is not valid';
export const SURNAME_TOO_SHORT = 'Surname is too short';
export const BIO_NOT_VALID = 'Bio is not valid';
export const BIO_TOO_LONG = 'Bio is too long';
export const WEBSITE_NOT_VALID = 'Website is not valid';
export const AVATAR_NOT_VALID = 'Avatar is not valid';
export const NO_MODIFICATIONS = 'No modifications';
export const USER_ALREADY_ADMIN = 'user already admin';
export const USER_NOT_ADMIN = 'user not admin';

// MARK: - Not found errors
export const USER_NOT_FOUND = 'User not found';
export const REFRESH_TOKEN_NOT_FOUND = 'Refresh token not found';
export const PLACE_NOT_FOUND = 'Place not found';

// MARK: - Internals errors
export const INTERNAL_ERROR = 'Internal error';
export const UNABLE_TO_SAVE = 'Unable to save';

// MARK: - Other errors
export const UNKNOWN_ERROR = 'Unknown error';

// MARK: - Follows errors
export const PLACE_ALREADY_FOLLOWED = 'Place already followed';
export const PLACE_NOT_FOLLOWED = 'Place not followed';

// MARK: - Recommendation errors
export const PLACE_ALREADY_RECOMMENDED = 'Place already recommended';
export const PLACE_NOT_RECOMMENDED = 'Place not recommended';

import StatCounter, { StatCounterProps } from './StatCounter';

interface StatsListProps {
	stats: Array<StatCounterProps>;
}

const StatsList = (props: StatsListProps) => {
	return (
		<ul className='stats-list'>
			{props.stats.map((stat, index) => (
				<li key={index} className='stats-list--item'>
					<StatCounter {...stat} />
				</li>
			))}
		</ul>
	);
};

export default StatsList;

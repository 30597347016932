import { useEffect, useRef } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Loader from './components/Loader';
import Navbar from './components/Navbar';
import ScrollToTop from './components/ScrollToTop';
import { useAuth } from './context/AuthContext';
import About from './pages/About';
import Home from './pages/Home';
import Login from './pages/Login';
import MyAccount from './pages/MyAccount';
import NotFound from './pages/NotFound';
import LostPassword from './pages/passwordLost/LostPassword';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Professional from './pages/Professional';
import Registration from './pages/registration/Registration';
import Security from './pages/Security';
import * as PlaceDetailAdmin from './pages/places/admin/PlaceDetail';
import PlaceEditor from './pages/places/admin/PlaceEditor';
import { getCurrentUser } from './tools/server/content/user';
import { User } from './tools/models/user';
import { NETWORK_ERROR, SERVER_ERROR, UNAUTHORIZED } from './tools/server/errors';
import { refresh_token } from './tools/server/auth/auth';
import { Barer } from './tools/models/barer';
import Logout from './pages/Logout';
import ValidateEmail from './pages/userEdit/ValidateEmail';
import CookiesBanner from './components/CookiesBanner';
import { useCookiesContext } from './context/CookiesContext';
import { createInstance, MatomoProvider } from '@jonkoops/matomo-tracker-react';
import PlaceSearch from './pages/places/public/PlaceSearch';
import PlaceDetail from './pages/places/public/PlaceDetails';

const SocialOMatic = () => {
	const { authState, authDispatch } = useAuth();
	const { cookiesState } = useCookiesContext();

	let disableTracking = true;
	if (cookiesState.cookies === true) {
		disableTracking = false;
	}

	const matomoInstance = useRef(
		createInstance({
			urlBase: 'https://analytics.o-matic.dev/',
			siteId: process.env.REACT_APP_MATOMO_SITE_ID ? parseInt(process.env.REACT_APP_MATOMO_SITE_ID) : 0,
			disabled: disableTracking,
			trackerUrl: 'https://analytics.o-matic.dev/matomo.php',
			srcUrl: 'https://analytics.o-matic.dev/matomo.js',
		})
	);

	//Get current user
	useEffect(() => {
		if (localStorage.getItem('token') !== null) {
			//Check if token is valid
			getCurrentUser(localStorage.getItem('token')!)
				.then((response: User) => {
					authDispatch({
						type: 'RESTORE_SESSION',
						payload: {
							token: localStorage.getItem('token')!,
							userId: response.id,
							username: response.username,
							firstName: response.firstName,
							lastName: response.lastName,
							email: response.email,
							bio: response.bio,
							createdAt: response.created_at,
							updatedAt: response.updated_at,
							emailValidatedAt: response.email_verified_at,
						},
					});
				})
				.catch((error: string) => {
					switch (error) {
						case SERVER_ERROR:
							alert('Impossible de se connecter au serveur. Merci de recharger la page.');
							noAuth();
							break;
						case NETWORK_ERROR:
							alert('Vous êtes hors ligne. Merci de vérifier votre connexion internet.');
							noAuth();
							break;
						case UNAUTHORIZED:
							//Token is no longer valid. Need to use the refers token
							//TODO: Handle unauthorized error
							if (localStorage.getItem('refreshToken') !== null) {
								//Refresh the token
								refresh_token(localStorage.getItem('refreshToken')!)
									.then((response: Barer) => {
										localStorage.setItem('token', response.access_token);
										localStorage.setItem('refreshToken', response.refresh_token);
										getCurrentUser(localStorage.getItem('token')!)
											.then((response: User) => {
												authDispatch({
													type: 'RESTORE_SESSION',
													payload: {
														token: localStorage.getItem('token')!,
														userId: response.id,
														username: response.username,
														firstName: response.firstName,
														lastName: response.lastName,
														email: response.email,
														bio: response.bio,
														createdAt: response.created_at,
														updatedAt: response.updated_at,
														emailValidatedAt: response.email_verified_at,
													},
												});
											})
											.catch((error: string) => {
												//An error has occurred
												noAuth();
											});
									})
									.catch((error: string) => {
										//Token is no longer valid
										localStorage.removeItem('token');
										localStorage.removeItem('refreshToken');
										noAuth();
									});
							} else {
								//Can't reconnect the user
								localStorage.removeItem('token');
								noAuth();
							}
							break;
						default:
							//TODO: Handle unknown error
							alert('Une erreur inconnue est survenue. Merci de recharger la page.');
							noAuth();
							break;
					}
				});
		} else {
			//User is logged out
			noAuth();
		}
		// eslint-disable-next-line
	}, []);

	const noAuth = () => {
		authDispatch({
			type: 'RESTORE_SESSION',
			payload: {
				token: null,
				userId: null,
				username: null,
				firstName: null,
				lastName: null,
				email: null,
				bio: null,
				createdAt: null,
				updatedAt: null,
			},
		});
	};

	if (authState.isLoading) {
		return <Loader className='loader--start' />;
	}

	return (
		<MatomoProvider value={matomoInstance.current}>
			<BrowserRouter>
				<Navbar />
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/about' element={<About />} />
					<Route path='/security' element={<Security />} />
					<Route path='/pro' element={<Professional />} />
					<Route path='/privacy-policy' element={<PrivacyPolicy />} />
					<Route path='/login' element={<Login />} />
					<Route path='/register' element={<Registration />} />
					<Route path='/forgot-password' element={<LostPassword />} />
					<Route path='/place'>
						<Route index element={<PlaceSearch />} />
						<Route path=':placeId' element={<PlaceDetail />} />
					</Route>
					<Route path='/my-account'>
						<Route index element={<MyAccount />} />
						<Route path='validate-email' element={<ValidateEmail />} />
					</Route>
					<Route path='/admin'>
						<Route path='place/create' element={<PlaceEditor />} />
						<Route path='place/edit/:placeId' element={<PlaceEditor />} />
						<Route path='place/:placeId' element={<PlaceDetailAdmin.PlaceDetail />} />
					</Route>
					<Route path='/logout' element={<Logout />} />
					<Route path='*' element={<NotFound />} />
				</Routes>
				<Footer />
				<ScrollToTop />
				<CookiesBanner />
			</BrowserRouter>
		</MatomoProvider>
	);
};

export default SocialOMatic;

import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { send_verification_code } from '../tools/server/auth/auth';
import { ALREADY_SENDED, EMAIL_REJECTED } from '../tools/server/errors';
import Loader from './Loader';
import Notice from './Notice';

interface VerificationCodeProps {
	result: (value: number) => void;
	email: string;
	forPassword: boolean;
	noSendAtBoot?: boolean;
}

const VerificationCode = (props: VerificationCodeProps) => {
	const { result, email, forPassword } = props;
	const [isLoading, setIsLoading] = useState<Boolean>(true);
	const [error, setError] = useState<string | null>(null);
	const [information, setInformation] = useState<string | null>(null);

	const validationSchema = Yup.object().shape({
		vcode: Yup.number()
			.integer('Le code doit être un nombre')
			.typeError('Le code doit être un nombre')
			.required('Ce champ est obligatoire')
			.min(100000, 'Le code doit contenir 6 chiffres')
			.max(999999, 'Le code doit contenir 6 chiffres'),
	});

	useEffect(() => {
		//Send a code to the email
		if (email !== '') {
			boot(props.noSendAtBoot ? false : true);
		}
		// eslint-disable-next-line
	}, [email, props.noSendAtBoot]);

	const boot = (withSend: boolean) => {
		setIsLoading(true);
		setError(null);
		setInformation(null);

		//Ask for send the verification code
		if (!withSend) {
			setIsLoading(false);
			return;
		} else {
			send_verification_code(email, forPassword)
				.then(() => {
					//Server has send the verification code
					setIsLoading(false);
				})
				.catch((error: string) => {
					//The server returned an error
					switch (error) {
						case ALREADY_SENDED: {
							setInformation('Un code a été envoyé à cette adresse. Pensez à bien vérifier vos spams.');
							break;
						}
						case EMAIL_REJECTED: {
							setError('Cette adresse email est invalide');
							break;
						}
						default: {
							setError('Une erreur est survenue');
							break;
						}
					}
					setIsLoading(false);
				});
		}
	};

	if (isLoading) {
		return (
			<div className='verification-code'>
				<Loader />
			</div>
		);
	}

	return (
		<Formik
			initialValues={{ vcode: '' }}
			validationSchema={validationSchema}
			onSubmit={(value) => {
				result(parseInt(value.vcode));
			}}>
			<Form>
				{error && (
					<div className='form-group'>
						<Notice type='error'>{error}</Notice>
					</div>
				)}
				{information && (
					<div className='form-group'>
						<Notice type='info'>{information}</Notice>
					</div>
				)}
				<div className='verification-code form-group'>
					<div className='verification-code-digits'>
						<label htmlFor='vcode'>Code de vérification</label>
						<Field type='numeric' name='vcode' id='vcode' className='verification-code-digit' />
					</div>
					<button type='submit' className='form-circular-button'>
						<i className='fa-solid fa-arrow-right'></i>
					</button>
				</div>
				<ErrorMessage name='vcode' component='div' className='form-error' />
				<div className='form-group'>
					<button
						className='button--black'
						onClick={() => {
							boot(true);
						}}
						type='button'>
						Renvoyer un code
					</button>
				</div>
			</Form>
		</Formik>
	);
};

export default VerificationCode;

import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/Loader';
import Notice from '../../../components/Notice';
import Page from '../../../components/Page';
import { PlacePostProps } from '../../../components/PlacePost';
import PlacePostList from '../../../components/PlacePostList';
import RestrictedArea from '../../../components/RestrictedArea';
import StatsList from '../../../components/StatsList';
import { useAuth } from '../../../context/AuthContext';
import { Pagination } from '../../../tools/models/pagination';
import { Place } from '../../../tools/models/place';
import { Post } from '../../../tools/models/post';
import { get_page_post, get_place, get_place_admin, send_place_post } from '../../../tools/server/content/place';
import { PLACE_NOT_FOUND, UNAUTHORIZED } from '../../../tools/server/errors';
import * as Yup from 'yup';

export const PlaceDetail = () => {
	const { placeId } = useParams();
	const { authState } = useAuth();
	const navigate = useNavigate();
	const [place, setPlace] = useState<null | Place>(null);
	const [posts, setPosts] = useState<Array<PlacePostProps>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [postLoading, setPostLoading] = useState<boolean>(true);
	const [sendLoading, setSendLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const [postPage, setPostPage] = useState<number>(1);
	const [maxDate, setMaxDate] = useState<Date>(new Date());
	const [canLoadMore, setCanLoadMore] = useState<boolean>(false);

	const boot = async () => {
		setIsLoading(true);
		setError(null);
		setPosts([]);

		//Get place
		if (placeId) {
			try {
				const result = await get_place(placeId);
				setPlace(result);
				//Check if user is an admin
				await get_place_admin(authState.token, placeId);

				//Get posts
				await loadPosts(placeId, 1, true, new Date());
			} catch (error) {
				switch (error) {
					case PLACE_NOT_FOUND:
						navigate('not-found', { replace: false });
						break;
					case UNAUTHORIZED:
						navigate('my-account');
						break;
					default:
						setError('Une erreur est survenue');
						break;
				}
			}
		} else {
			navigate('/my-account');
		}
		setIsLoading(false);
	};

	const loadPosts = async (id: string, page = postPage, reset = false, dateLimit = maxDate) => {
		setPostLoading(true);
		setError(null);
		try {
			//Get posts
			const new_post: Pagination<Post> = await get_page_post(id, dateLimit, page);
			if (reset) {
				if (new_post.data) {
					let formated_posts: Array<PlacePostProps> = [];
					new_post.data.forEach((post) => {
						formated_posts.push({
							id: post.id,
							name: post.place.name,
							logo: post.place.avatar ? post.place.avatar : '',
							date: post.created_at ? new Date(post.created_at) : new Date(),
							content: post.content,
						});
					});
					setPosts(formated_posts);
				} else {
					setPosts([]);
				}
			} else {
				if (new_post.data) {
					let formated_posts: Array<PlacePostProps> = [];
					new_post.data.forEach((post) => {
						formated_posts.push({
							id: post.id,
							name: post.place.name,
							logo: post.place.avatar ? post.place.avatar : '',
							date: post.created_at ? new Date(post.created_at) : new Date(),
							content: post.content,
						});
					});
					setPosts([...posts, ...formated_posts]);
				}
			}

			//Check if there is more posts
			setCanLoadMore(new_post.next_page_url ? true : false);
			setPostPage(page + 1);
			setMaxDate(dateLimit);
		} catch (error) {
			setError('Une erreur est survenue');
		}
		setPostLoading(false);
	};

	useEffect(() => {
		boot();
		// eslint-disable-next-line
	}, [placeId]);

	const handleSendPost = async (content: string) => {
		setSendLoading(true);
		setError(null);
		try {
			await send_place_post(authState.token, placeId!, content);
			await boot();
		} catch (error) {
			switch (error) {
				case UNAUTHORIZED:
					setError("Vous n'êtes pas autorisé à effectuer cette action");
					break;
				default:
					setError('Une erreur est survenue');
					break;
			}
		}
		setSendLoading(false);
	};

	if (isLoading) {
		return (
			<RestrictedArea needAuth={true}>
				<div className='page page-place-details'>
					<div className='container'>
						<Loader />
					</div>
				</div>
			</RestrictedArea>
		);
	}

	if (error) {
		return (
			<RestrictedArea needAuth={true}>
				<div className='page page-place-details'>
					<div className='container'>
						<Notice type='error'>{error}</Notice>
					</div>
				</div>
			</RestrictedArea>
		);
	}

	return (
		<RestrictedArea needAuth={true}>
			<Page title={place?.name} pageClass='place-details'>
				<div className='container'>
					<section className='row page-place-details--header'>
						<div className='col-xs-4 col-sm-12'>
							<h1 className='page--title'>{place?.name}</h1>
							<div className='page-place-details--stats'>
								<StatsList
									stats={[
										{
											value: place?.recommendations!,
											icon: 'thumbs-up',
										},
										{
											value: place?.followers!,
											icon: 'users',
										},
									]}
								/>
							</div>
							<p className='page-place-details--address'>{place?.address}</p>
							<Link to={`/admin/place/edit/${place?.id}`} className='button--black'>
								Modifier la page
							</Link>
						</div>
					</section>
					<section className='row page-place-details--post'>
						<div className='col-xs-4 col-sm-12 col-md-6'>
							{sendLoading ? (
								<Loader />
							) : (
								<Formik
									initialValues={{
										post: '',
									}}
									validationSchema={Yup.object().shape({
										post: Yup.string().required('Champ requis'),
									})}
									onSubmit={(values) => {
										handleSendPost(values.post);
									}}>
									<Form>
										<div className='form-group'>
											<label className='page--subtitle' htmlFor='post'>
												Envoyer un post
											</label>
											<Field as='textarea' name='post' id='post' />
											<ErrorMessage name='post' component='div' className='form-error' />
										</div>
										<div className='form-group'>
											<button type='submit' className='button--black'>
												Poster une news
											</button>
										</div>
									</Form>
								</Formik>
							)}
						</div>
						<div className='col-xs-4 col-sm-12 col-md-6'>
							<h2 className='page--subtitle'>Anciens posts</h2>
							{posts.length > 0 ? <PlacePostList posts={posts} canDelete={true} /> : <Notice type='info'>Aucun post pour le moment</Notice>}
							{postLoading && <Loader />}
							{canLoadMore && postLoading === false && (
								<button
									className='button--black page-place-details--load-more'
									onClick={() => {
										loadPosts(placeId!);
									}}>
									Voir plus de post
								</button>
							)}
						</div>
					</section>
				</div>
			</Page>
		</RestrictedArea>
	);
};

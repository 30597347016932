import { AxiosRequestConfig } from 'axios';

/**
 * Function used to generate the axios headers
 * @param barer The barer token
 * @returns The axios request config headers
 */
export const headerGenerator = (barer?: string): AxiosRequestConfig<any> => {
    let headers;

    if(barer) {
        headers = {
			Accept: 'application/json',
			'Content-Type': 'multipart/form-data',
			Authorization: `Bearer ${barer}`,
		};
    } else {
        headers = {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
        };
    }

    const axiosHeaders: AxiosRequestConfig<any> = {
        headers: headers,
    }

    return axiosHeaders;
}
import Lottie from 'react-lottie';
import Phone from '../assets/images/phone/screen1.png';
import SomParallaxLogo from '../assets/images/parallax/logo-parallax.svg';
import Handshake from '../assets/images/icons/handshake-solid-orange.svg';
import Store from '../assets/images/icons/shop-solid-orange.svg';
import Page from '../components/Page';
import HeroParallax from '../components/HeroParallax';
import TextWithImage from '../components/TextWithImage';
import Reinsurance from '../components/Reinsurance';
import { Link } from 'react-router-dom';
import Downloads from '../components/Download';

const Home = () => {
	return (
		<Page pageClass='home'>
			<HeroParallax
				title='Fini les messages de groupes pour s’organiser,
place au Social O Matic pour simplifier'
				frontImage={Phone}
				backImage={SomParallaxLogo}
			/>
			<TextWithImage
				content={
					<>
						<p>Aujourd’hui le digital prend de plus en plus de place dans nos vies, parfois au prix d’effacer notre vie sociale dans le monde réel.</p>
						<p>Le Social O Matic vous permet de vous retrouver dans la vraie vie et de découvrir de nouveaux lieux autour de vous.</p>
					</>
				}
				side={
					<Lottie
						options={{
							animationData: require('../assets/lottie/illustrations_social_friends.json'),
							loop: true,
						}}
					/>
				}
				cta={{
					text: 'En savoir plus',
					link: '/about',
					local: true,
					color: 'primary',
				}}
				reverse={false}
				large={true}
			/>
			<Reinsurance
				title='Le Social O Matic...'
				items={[
					{
						title: 'pour tous',
						icon: Handshake,
						content: (
							<>
								<p>Le Social O Matic a été créé afin de tous vous/nous réunir autour de moments de partages.</p>
								<p>
									Retrouvez tout dans une seule application : vos amis comme des lieux gérés au jour le jour par des professionnels qui sont en charge de leur
									page.
								</p>
							</>
						),
					},
					{
						title: 'pour les professionnels',
						icon: Store,
						content: (
							<>
								<p>
									Après la crise que nous avons traversé, nous vous proposons une nouvelle manière de parler de votre activité pour vous offrir une nouvelle
									vitrine numérique.
								</p>
								<p>
									Adieu le nombre d’étoiles et place aux recommandations ! Nous ne souhaitons pas que votre image soit dégradée par des commentaires haineux,
									mais vous proposons plutôt de mettre en avant le nombre de personnes qui vous aiment, vous suivent.
								</p>
								<Link to='/pro' className='button--black'>
									En savoir plus
								</Link>
							</>
						),
					},
				]}
			/>
			<TextWithImage
				title='La sécurité nous tient à coeur !'
				content={
					<>
						<p>Nous ne regardons ni vos soirées ni même ne regardons votre nom.</p>
						<p>Votre profil est stocké de manière cryptée et nous changeons régulièrement notre clé de cryptage pour éviter les fuites de données.</p>
						<p>Seules les personnes que vous invitez dans votre événement peuvent vous voir, vous et vos détails.</p>
					</>
				}
				side={
					<Lottie
						options={{
							animationData: require('../assets/lottie/illustrations_social_verres.json'),
							loop: true,
						}}
					/>
				}
				cta={{
					text: 'En savoir plus sur la sécurité',
					link: '/security',
					local: true,
					color: 'primary',
				}}
				large={false}
				reverse={false}
			/>
			<Downloads />
		</Page>
	);
};

export default Home;

import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import Loader from '../../components/Loader';
import Notice from '../../components/Notice';
import { useAuth } from '../../context/AuthContext';
import * as Yup from 'yup';
import { update_user } from '../../tools/server/auth/auth';

const Password = () => {
	const { authState } = useAuth();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const validationSchema = Yup.object().shape({
		password: Yup.string()
			.required('Ce champ est obligatoire')
			.min(8, 'Ce champ doit contenir au moins 8 caractères')
			.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\s\S]{8,}$/, 'Ce champ doit contenir au moins 1 majuscule, 1 minuscule et 1 chiffre'),
		passwordConfirm: Yup.string()
			.required('Ce champ est obligatoire')
			.oneOf([Yup.ref('password'), null], 'Les mots de passe ne correspondent pas'),
	});

	const handleSubmit = async (values: any) => {
		setErrorMessage(null);
		setIsLoading(true);
		try {
			await update_user(authState.token, undefined, undefined, undefined, undefined, values.password);
			setIsLoading(false);
		} catch (error) {
			setErrorMessage('Une erreur est survenue lors de la mise à jour. Veuillez réessayer.');
			setIsLoading(false);
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<Formik
			initialValues={{
				password: '',
				passwordConfirm: '',
			}}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				handleSubmit(values);
			}}>
			<Form>
				{errorMessage && (
					<div className='form-group'>
						<Notice type='error'>{errorMessage}</Notice>
					</div>
				)}
				<div className='form-group form-row'>
					<div className='form-col'>
						<div className='form-group'>
							<label htmlFor='password'>Mot de passe</label>
							<Field name='password' type='password' />
							<ErrorMessage name='password' component='div' className='form-error' />
						</div>
					</div>
					<div className='form-col'>
						<div className='form-group'>
							<label htmlFor='passwordConfirm'>Confirmation du mot de passe</label>
							<Field name='passwordConfirm' type='password' />
							<ErrorMessage name='passwordConfirm' component='div' className='form-error' />
						</div>
					</div>
				</div>
				<div className='form-group form-group-submit'>
					<button type='submit' className='button--black'>
						Sauvegarder
					</button>
				</div>
			</Form>
		</Formik>
	);
};

export default Password;

import { Link } from 'react-router-dom';
import { useCookiesContext } from '../context/CookiesContext';

const CookiesBanner = () => {
	const { cookiesState, cookiesDispatch } = useCookiesContext();

	if (cookiesState.cookies !== null) {
		return <></>;
	}

	return (
		<div className='cookies-banner'>
			<p className='cookies-banner--title'>Une simple affaire de cookies !</p>
			<p className='cookies-banner--text'>
				Afin de vous proposer de meilleures fonctionnalités, nous utilisons des cookies. En poursuivant votre navigation sur ce site, vous acceptez
				l'utilisation de cookies.
				<br />
				<br />
				Sachez, que nous ne servons de cookies que pour comprendre comment vous utilisez notre site et pour améliorer votre expérience utilisateur.
			</p>
			<div className='links'>
				<Link to='/privacy-policy' className='cookies-banner--link'>
					Lire notre politique de confidentialité
				</Link>
			</div>
			<div className='buttons'>
				<button
					className='button button--primary'
					onClick={() => {
						cookiesDispatch({ type: 'SET_COOKIES', value: true });
						window.location.reload();
					}}>
					D'accord je veux bien vous aider !
				</button>
				<button
					className='button button--black'
					onClick={() => {
						cookiesDispatch({ type: 'SET_COOKIES', value: false });
						window.location.reload();
					}}>
					Non merci, j'ai pas faim !
				</button>
			</div>
		</div>
	);
};

export default CookiesBanner;

import { createContext, Context, ReactNode, useReducer, useContext } from 'react';
import { AuthState } from '../tools/models/AuthState';

const AuthContext: Context<any> = createContext(undefined);

export type AuthAction =
	| {
			type: 'LOGIN';
			payload: {
				token: string;
				userId: string;
				username: string;
				firstName: string;
				lastName: string;
				email: string;
				bio: string;
				createdAt: string | null;
				updatedAt: string | null;
				emailValidatedAt: string | null;
			};
	  }
	| {
			type: 'LOGOUT';
	  }
	| {
			type: 'RESTORE_SESSION';
			payload: {
				token: string | null;
				userId: string | null;
				username: string | null;
				firstName: string | null;
				lastName: string | null;
				email: string | null;
				bio: string | null;
				createdAt: string | null;
				updatedAt: string | null;
				emailValidatedAt: string | null;
			};
	  };

interface AuthProviderProps {
	children?: ReactNode;
}

const initialState: AuthState = {
	isLoading: true,
	token: null,
	userId: null,
	username: null,
	email: null,
	bio: null,
	createdAt: null,
	updatedAt: null,
	firstName: null,
	lastName: null,
	emailValidatedAt: null,
};

function authReducer(state: AuthState, action: AuthAction) {
	switch (action.type) {
		case 'LOGIN':
			return {
				...state,
				token: action.payload.token,
				userId: action.payload.userId,
				username: action.payload.username,
				firstName: action.payload.firstName,
				lastName: action.payload.lastName,
				email: action.payload.email,
				bio: action.payload.bio,
				createdAt: action.payload.createdAt,
				updatedAt: action.payload.updatedAt,
				emailValidatedAt: action.payload.emailValidatedAt,
			};
		case 'LOGOUT':
			return {
				...state,
				token: null,
				userId: null,
				username: null,
				firstName: null,
				lastName: null,
				email: null,
				bio: null,
				createdAt: null,
				updatedAt: null,
				emailValidatedAt: null,
			};
		case 'RESTORE_SESSION':
			return {
				...state,
				isLoading: false,
				token: action.payload.token,
				userId: action.payload.userId,
				username: action.payload.username,
				firstName: action.payload.firstName,
				lastName: action.payload.lastName,
				email: action.payload.email,
				bio: action.payload.bio,
				createdAt: action.payload.createdAt,
				updatedAt: action.payload.updatedAt,
				emailValidatedAt: action.payload.emailValidatedAt,
			};
		default:
			throw new Error('Invalid action type');
	}
}

export function AuthProvider(props: AuthProviderProps) {
	const [authState, authDispatch] = useReducer(authReducer, initialState);
	const value = { authState, authDispatch };

	return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}

export function useAuth() {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error('useAuth must be used within a AuthProvider');
	}
	return context;
}

import Lottie from 'react-lottie';
import Header from '../components/Header';
import Page from '../components/Page';
import Section from '../components/Section';
import TextWithImage from '../components/TextWithImage';

const Security = () => {
	return (
		<Page pageClass='security' title='Sécurité'>
			<Header title='Sécurité' subtitle='Nous veillons à garder au maximum votre vie privée !' lottie={require('../assets/lottie/shield.json')} />
			<TextWithImage
				content={
					<>
						<p>Seuls vous et ceux que vous invitez peuvent voir votre événement&nbsp;!</p>
						<p>
							Sur le Social O Matic, nous cryptons votre profil et vos événements. De ce fait, seuls vous et ceux qui ont le droit, peuvent voir votre
							événement.
						</p>
					</>
				}
				side={
					<Lottie
						options={{
							animationData: require('../assets/lottie/invites.json'),
							loop: true,
						}}
					/>
				}
				large={true}
				reverse={false}
			/>
			<Section color='white'>
				<div className='col-xs-4 col-sm-12 section--body'>
					<h3 className='section--title'>Vos données sont en Suisse et elles y restent !</h3>
					<p className='section--text'>
						Nous aimons tout ce qui est local ! Y compris nos serveurs ! Nous avons comme objectif de garder vos données au centre de l’Europe où le niveau
						d’exigence en terme de protection des données est élevé.
					</p>
					<p className='section--text'>Pour ce faire, nous avons choisi le leader suisse de l’hébergement web en qui nous avons la plus grande confiance.</p>
				</div>
			</Section>
			<TextWithImage
				title='Vos données, votre propriété !'
				content={
					<>
						<p>
							Nous sommes contre le marchandage de données personnelles. C’est pour cela que nous souhaitons être le plus transparent possible sur les données
							que nous collectons et veillons à n’utiliser que ce qui est nécessaire au bon fonctionnement de l’application.
						</p>
					</>
				}
				side={
					<Lottie
						options={{
							animationData: require('../assets/lottie/illustrations_social_portable.json'),
							loop: true,
						}}
					/>
				}
				large={false}
				reverse={false}
			/>
			<TextWithImage
				title='Vous partez ? Alors on vous oublie !'
				content={
					<>
						<p>
							Si malheureusement vous ne souhaitez plus utiliser le Social O Matic, vous supprimez votre compte et nous supprimons automatiquement l’intégralité
							de vos données (Profil, événement, amis, jetons de connexion, etc…)
						</p>
					</>
				}
				side={
					<Lottie
						options={{
							animationData: require('../assets/lottie/illustrations_social_verres.json'),
							loop: true,
						}}
					/>
				}
				large={false}
				reverse={true}
			/>
		</Page>
	);
};

export default Security;

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import RestrictedArea from '../components/RestrictedArea';
import { useAuth } from '../context/AuthContext';
import { logout } from '../tools/server/auth/auth';

const Logout = () => {
	const { authState, authDispatch } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		if (authState.token) {
			logout(authState.token)
				.then(() => {
					localStorage.removeItem('token');
					localStorage.removeItem('refreshToken');
					authDispatch({ type: 'LOGOUT' });
				})
				.catch(() => {
					navigate('/my-account');
				});
		}
		// eslint-disable-next-line
	}, [authState]);

	return (
		<RestrictedArea needAuth={true}>
			<Loader />
		</RestrictedArea>
	);
};

export default Logout;

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/Loader';
import Notice from '../../../components/Notice';
import Page from '../../../components/Page';
import VerificationCode from '../../../components/VerificationCode';
import { AuthAction, useAuth } from '../../../context/AuthContext';
import { useRegister } from '../../../context/RegisterContext';
import { Barer } from '../../../tools/models/barer';
import { login, logout, validate_email } from '../../../tools/server/auth/auth';
import { getCurrentUser } from '../../../tools/server/content/user';
import { BAD_CODE } from '../../../tools/server/errors';

const ValidateEmail = () => {
	const { registerState, registerDispatch } = useRegister();
	const { authDispatch } = useAuth();
	const navigate = useNavigate();
	const [authToken, setAuthToken] = useState<Barer | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		setIsLoading(true);
		//Get user barrer token
		login(registerState.email, registerState.password)
			.then((results: Barer) => {
				setAuthToken(results);
				setIsLoading(false);
			})
			.catch(() => {
				navigate('/login');
				setIsLoading(false);
			});
		// eslint-disable-next-line
	}, [registerState.email, registerState.password]);

	const handle = (code: any) => {
		setIsLoading(true);
		setError(null);
		validate_email(code, authToken!.access_token)
			.then(() => {
				getCurrentUser(authToken!.access_token)
					.then((results) => {
						const userResult: AuthAction = {
							type: 'LOGIN',
							payload: {
								token: authToken!.access_token,
								userId: results.id,
								username: results.username,
								firstName: results.firstName,
								lastName: results.lastName,
								email: results.email,
								bio: results.bio,
								createdAt: results.created_at,
								updatedAt: results.updated_at,
								emailValidatedAt: results.email_verified_at,
							},
						};
						localStorage.setItem('token', authToken!.access_token);
						localStorage.setItem('refreshToken', authToken!.refresh_token);
						authDispatch(userResult);
						registerDispatch({ type: 'RESET' });
						setIsLoading(false);
					})
					.catch(() => {
						logout(authToken!.access_token);
						navigate('/login');
						setIsLoading(false);
					});
			})
			.catch((error: any) => {
				switch (error) {
					case BAD_CODE: {
						setError("Ce code n'est pas valide ou a expiré");
						break;
					}
					default: {
						setError('Une erreur est survenue');
						break;
					}
				}
				setIsLoading(false);
			});
	};

	return (
		<Page title='Valider son adresse mail' pageClass='registration-email-validation'>
			<div className='container'>
				<h1 className='page-registration-title'>Confirmer son adresse e-mail</h1>
				<p className='page-registration-subtitle'>
					Pour profiter des toutes les fonctionnalités du Social O Matic, Vous devez confirmer que vous avez accès à l’adresse e-mail que vous avez fournis à
					l’aide du code à 6 Chiffres que nous vous avons envoyé. (Pensez à vérifier vos spams)
				</p>
				{error && <Notice type='error'>{error}</Notice>}
				{isLoading ? (
					<Loader />
				) : (
					<div className='page-registration-email-validation-code'>
						<VerificationCode result={(value) => handle(value)} email={registerState.email} forPassword={false} />
					</div>
				)}
			</div>
		</Page>
	);
};

export default ValidateEmail;

interface PlaceDetailsEventsListProps {
	placeId: string;
}

const PlaceDetailsEventsList = (props: PlaceDetailsEventsListProps) => {
	// eslint-disable-next-line
	const { placeId } = props;
	return (
		<div className='page-place-details--events-content'>
			<p className='page--title'>Prochainement...</p>
			<p className='info'>Découvrez les événements proposés par ce lieu dans une prochaine mise&nbsp;à&nbsp;jour&nbsp;!</p>
		</div>
	);
};

export default PlaceDetailsEventsList;

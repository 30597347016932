import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { AuthAction, useAuth } from '../../context/AuthContext';
import * as Yup from 'yup';
import { update_user } from '../../tools/server/auth/auth';
import Notice from '../../components/Notice';
import Loader from '../../components/Loader';

const Identity = () => {
	const { authState, authDispatch } = useAuth();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const validationSchema = Yup.object().shape({
		firstName: Yup.string().required('Obligatoire').min(2, 'Doit contenir au moins 2 caractères'),
		name: Yup.string().required('Obligatoire').min(2, 'Doit contenir au moins 2 caractères'),
	});

	const handleSubmit = async (values: any) => {
		setErrorMessage(null);
		setIsLoading(true);
		try {
			const response = await update_user(authState.token, values.name, values.firstName);
			if (response !== null) {
				const update: AuthAction = {
					type: 'RESTORE_SESSION',
					payload: {
						...authState,
						firstName: response.firstName,
						lastName: response.lastName,
					},
				};
				authDispatch(update);
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		} catch (errors: any) {
			setIsLoading(false);
			setErrorMessage('Une erreur est survenue lors de la mise à jour. Veuillez réessayer.');
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<Formik
			initialValues={{
				firstName: authState.firstName,
				name: authState.lastName,
			}}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				handleSubmit(values);
			}}>
			<Form>
				{errorMessage && (
					<div className='form-group'>
						<Notice type='error'>{errorMessage}</Notice>
					</div>
				)}
				<div className='form-group form-row'>
					<div className='form-col'>
						<div className='form-group'>
							<label htmlFor='name'>Nom</label>
							<Field name='name' />
							<ErrorMessage name='name' component='div' className='form-error' />
						</div>
					</div>
					<div className='form-col'>
						<div className='form-group'>
							<label htmlFor='firstName'>Prénom</label>
							<Field name='firstName' />
							<ErrorMessage name='firstName' component='div' className='form-error' />
						</div>
					</div>
				</div>
				<div className='form-group form-group-submit'>
					<button type='submit' className='button--black'>
						Sauvegarder
					</button>
				</div>
			</Form>
		</Formik>
	);
};

export default Identity;

import { AuthProvider } from './context/AuthContext';
import { CookiesProvider } from './context/CookiesContext';
import 'mapbox-gl/dist/mapbox-gl.css';
import './scss/app.scss';
import SocialOMatic from './SocialOMatic';

function App() {
	return (
		<CookiesProvider>
			<AuthProvider>
				<SocialOMatic />
			</AuthProvider>
		</CookiesProvider>
	);
}

export default App;

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Accordion from '../components/Accordion';
import Loader from '../components/Loader';
import Notice from '../components/Notice';
import Page from '../components/Page';
import PageLink from '../components/PageLink';
import RestrictedArea from '../components/RestrictedArea';
import { useAuth } from '../context/AuthContext';
import { Place } from '../tools/models/place';
import { getControlledPlace } from '../tools/server/content/place';
import { NETWORK_ERROR, SERVER_ERROR } from '../tools/server/errors';
import Biography from './userEdit/Biography';
import DeleteAccount from './userEdit/DeleteAccount';
import Email from './userEdit/Email';
import Identity from './userEdit/Identity';
import Notifications from './userEdit/Notifications';
import Password from './userEdit/Password';
import Username from './userEdit/Username';
import NewPlaceIcon from '../assets/images/icons/new-place-icon.svg';
import PlaceholderPlace from '../assets/images/icons/placeholder-place-icon.svg';

const MyAccount = () => {
	const { authState } = useAuth();
	const [placeLoading, setPlaceLoading] = useState(true);
	const [placeError, setPlacesError] = useState<string | null>();
	const [places, setPlaces] = useState<Array<Place>>([]);

	useEffect(() => {
		loadPlaces();
		// eslint-disable-next-line
	}, []);

	const loadPlaces = () => {
		setPlaceLoading(true);
		setPlacesError(null);
		getControlledPlace(authState.token)
			.then((result) => {
				setPlaces(result);
				setPlaceLoading(false);
			})
			.catch((error: string) => {
				setPlaceLoading(false);
				switch (error) {
					case SERVER_ERROR:
						setPlacesError('Notre serveur semble rencontrer quelques difficultés. Veuillez réessayer plus tard.');
						break;
					case NETWORK_ERROR:
						setPlacesError('Votre connection internet semble instable. Veuillez réessayer plus tard.');
						break;
					default:
						setPlacesError('Une erreur est survenue. Veuillez réessayer plus tard.');
						break;
				}
			});
	};

	return (
		<RestrictedArea needAuth={true}>
			<Page title='Mon compte' pageClass='my-account'>
				<div className='container'>
					<section className='page-my-account--header'>
						{authState.emailValidatedAt === null && (
							<Notice type='warning'>
								<p>
									<b>Veuillez vérifier votre adresse e-mail</b>
								</p>
								<p>Pour pouvoir profiter au maximum du Social O Matic, vous devez valider votre adresse e-mail {authState.email}.</p>
								<Link to='validate-email' className='button--black'>
									Valider mon e-mail
								</Link>
							</Notice>
						)}
						<h1 className='page--title'>Bonjour {authState.firstName}</h1>
						<p className='page--subtitle'>
							Sur cette page, personnalisez votre compte et gérez vos établissements dans les moindres détails. Si vous souhaitez gérer vos évènements privés,
							veuillez vous rendre sur l’application Social O Matic.
						</p>
						<Link to='/logout' className='page-my-account--logout'>
							Vous n'êtes pas {authState.firstName} ? Alors cliquez ici pour vous déconnecter
						</Link>
					</section>
					<section className='page-my-account--pages'>
						<div className='page-my-account--pages--header'>
							<h2 className='page--section-title'>
								<i className='fa-solid fa-store' />
								Mes établissements
							</h2>
						</div>
						{placeLoading ? (
							<div className='page-my-account--pages--loading'>
								<Loader />
							</div>
						) : (
							<>
								{placeError && (
									<Notice type='error'>
										<p>{placeError}</p>
										<button className='button--black' onClick={loadPlaces}>
											Réessayer
										</button>
									</Notice>
								)}
								<div className='page-my-account--pages--body'>
									{places.map((place) => (
										<PageLink key={place.id} id={place.id} name={place.name} logo={place.avatar ? place.avatar : PlaceholderPlace} />
									))}
									{placeError === null && <PageLink id='create' name='Ajouter un établissement' logo={NewPlaceIcon} />}
								</div>
							</>
						)}
					</section>
					<section className='page-my-account--edit'>
						<div className='page-my-account--edit--header'>
							<h2 className='page--section-title'>
								<i className='fa-solid fa-user' />
								Modifier mon profil
							</h2>
						</div>
						<div className='page-my-account--edit--body'>
							<Accordion
								items={[
									{
										title: 'Changer mon nom et mon prénom',
										notice: `${authState.firstName} ${authState.lastName}`,
										content: <Identity />,
									},
									{
										title: "Changer mon nom d'utilisateur",
										notice: authState.username,
										content: <Username />,
									},
									{
										title: 'Changer mon e-mail',
										notice: authState.email,
										content: <Email />,
									},
									{
										title: 'Changer mon mot de passe',
										content: <Password />,
									},
									{
										title: 'Changer ma biographie',
										content: <Biography />,
									},
									{
										title: 'Notifications',
										content: <Notifications />,
									},
									{
										title: 'Supprimer mon compte',
										content: <DeleteAccount />,
									},
								]}
							/>
						</div>
					</section>
				</div>
			</Page>
		</RestrictedArea>
	);
};

export default MyAccount;

export interface StatCounterProps {
	value: number;
	icon: string;
}

const StatCounter = (props: StatCounterProps) => {
	return (
		<p className='stat-counter'>
			<i className={`fa-solid fa-${props.icon}`}></i>
			<span className='stat-counter--value'>{props.value}</span>
		</p>
	);
};

export default StatCounter;

import Lottie from 'react-lottie';
import Header from '../components/Header';
import Page from '../components/Page';
import Section from '../components/Section';
import HeaderImage from '../assets/images/mockup/overview-SocialOMatic-phones.png';
import TextWithImage from '../components/TextWithImage';
import { Link } from 'react-router-dom';

const Professional = () => {
	return (
		<Page pageClass='pro' title='Professionnel'>
			<Header title='Professionnels' subtitle='Vous avez un établissement? Alors montrez le à tous ceux qui sont proches de vous !' image={HeaderImage} />
			<TextWithImage
				content={
					<>
						<p>Le Social O Matic veut rapprocher les personnes dans le monde réel, à savoir, chez vous, les professionnels du secteur !</p>
						<p>
							Si vous le souhaitez utiliser l'app pour présenter votre établissement et aider le monde à se rapprocher, n'hésitez pas à indiquer votre présence
							et votre capacité à accueillir des événements !
						</p>
					</>
				}
				side={
					<Lottie
						options={{
							animationData: require('../assets/lottie/illustrations_social_friends.json'),
							loop: true,
						}}
					/>
				}
				reverse={false}
				large={false}
			/>
			<Section color='white'>
				<div className='col-xs-4 col-sm-12 section--body'>
					<h3 className='section--title'>Finies les étoiles, ne gardez que le meilleur</h3>
					<p className='section--text'>
						Sur le Social O Matic, nous avons fait le choix de ne pas utiliser un système de notation pour mettre en avant la réputation de votre établissement.
						Nous comptons uniquement les personnes qui vous recommandent pour éviter les tentatives de sabotage.
					</p>
				</div>
			</Section>
			<TextWithImage
				title='Local avant tout !'
				content={
					<>
						<p>Pensé, designé et développé en Suisse, nous soutenons tout ce qui est local jusqu’à notre hébergeur, également en Suisse !</p>
						<p>
							Toutes vous données sont stockées sur des serveurs sécurisés et sont supprimées dès que nous en avons plus besoin ou lorsque vous supprimez votre
							compte.
						</p>
						<Link to='/confidentiality' className='button--primary'>
							En savoir plus
						</Link>
					</>
				}
				side={
					<Lottie
						options={{
							animationData: require('../assets/lottie/illustrations_social_verres.json'),
							loop: true,
						}}
					/>
				}
				large={false}
				reverse={true}
			/>
		</Page>
	);
};

export default Professional;

import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Page from '../../../components/Page';
import Section from '../../../components/Section';
import * as Yup from 'yup';
import Loader from '../../../components/Loader';
import { Link } from 'react-router-dom';
import StatsList from '../../../components/StatsList';
import { get_types, search_place_by_name } from '../../../tools/server/content/place';
import { PlaceType } from '../../../tools/models/type';
import { Place } from '../../../tools/models/place';
import PlaceHolder from '../../../assets/images/icons/placeholder-place-icon.svg';
import { useAuth } from '../../../context/AuthContext';

const PlaceSearch = () => {
	const { authState } = useAuth();
	const [pageTitle, setPageTitle] = useState<string>('Rechercher un lieux');
	const [searchLoading, setSearchLoading] = useState<boolean>(false); //Add loader at the end of the page
	const [isLoading, setIsLoading] = useState<boolean>(true); //General Loader
	const [loadMore, setLoadMore] = useState<boolean>(false); //Show the load more button
	const [noResult, setNoResult] = useState<boolean>(false); //Show if there is no result
	const [types, setTypes] = useState<Array<PlaceType>>([]);
	const [places, setPlaces] = useState<Array<Place>>([]);
	const [page, setPage] = useState<number>(1);
	const [type, setType] = useState<string>('*');
	const [query, setQuery] = useState<string>('');

	const initialValues = {
		searchField: '',
		typeSearch: '*',
	};

	const validationSchema = Yup.object({
		searchField: Yup.string().required('Ce champ est obligatoire'),
	});

	const loadPlace = (desiredQuery: string, desiredType: string, list: Array<Place>, desiredPage: number) => {
		setSearchLoading(true);
		search_place_by_name(desiredQuery, desiredPage, desiredType === '*' ? undefined : desiredType)
			.then((result) => {
				//Prepare the list
				if (result.data) {
					setPlaces(list.concat(result.data));
					if (result.total === 0) {
						setNoResult(true);
					} else {
						setNoResult(false);
					}
				}
				if (desiredPage >= result.last_page) {
					setLoadMore(false);
				}

				//Defines values for pagination requests
				setType(desiredType);
				setQuery(desiredQuery);
				setPage(desiredPage);

				//Done
				setSearchLoading(false);
			})
			.catch((error: any) => {
				console.error(error);
				setSearchLoading(false);
			});
	};

	//Search request
	const onSubmit = (values: any) => {
		loadPlace(values.searchField, values.typeSearch, [], 1);
		setPageTitle(`Résultats de la recherche pour "${values.searchField}"`);
	};

	//Load next page
	const loadMorePlaces = () => {
		loadPlace(query, type, places, page + 1);
	};

	//Boot
	useEffect(() => {
		get_types()
			.then((result: Array<PlaceType>) => {
				setTypes(result);
				setIsLoading(false);
			})
			.catch((error: any) => {
				console.error(error);
				setIsLoading(false);
			});
	}, []);

	return (
		<Page title={pageTitle} pageClass='place-search'>
			{isLoading ? (
				<div className='page-place-search--loader'>
					<Loader />
				</div>
			) : (
				<>
					<Section className='page-place-search--header' color='default'>
						<div className='col-xs-4 col-sm-12'>
							<h2 className='page-place-search--title page--title'>Envie de sortir ?</h2>
							<p className='page-place-search--sub-title'>Découvrez si vos établissements favoris sont sur le Social&nbsp;O&nbsp;Matic</p>
							<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
								<Form className='search-field'>
									<div className='form-group'>
										<label htmlFor='searchField'>Nom de l'établissement</label>
										<Field type='text' name='searchField' id='searchField' />
									</div>
									<div className='form-group'>
										<label htmlFor='typeSearch'>Type de l'établissement</label>
										<Field as='select' name='typeSearch' id='typeSearch'>
											<option value='*'>Tout type</option>
											{types.map((type: PlaceType) => (
												<option key={type.id} value={type.id}>
													{type.name}
												</option>
											))}
										</Field>
									</div>
									<div className='form-group form-group-submit'>
										<button type='submit' className='button--primary'>
											Rechercher
										</button>
									</div>
								</Form>
							</Formik>
						</div>
					</Section>
					<Section className='page-place-search--results' color='default'>
						<div className='col-xs-4 col-sm-12'>
							<div className='page-place-search--grid'>
								{places.map((place: Place) => (
									<Link key={place.id} to={`/place/${place.id}`} className='page-place-search--grid-item'>
										<div className='page-place-search--grid-item-head'>
											<div className='place-name'>
												<img src={place.avatar ? place.avatar : PlaceHolder} alt={place.name} />
												<h3>{place.name}</h3>
												<p>{place.type.name}</p>
											</div>
											<StatsList
												stats={[
													{
														value: place.recommendations_count ? place.recommendations_count : 0,
														icon: 'thumbs-up',
													},
													{
														value: place.followers_count ? place.followers_count : 0,
														icon: 'users',
													},
												]}
											/>
											<p className='place-address'>{place.address}</p>
										</div>
										<div className='page-place-search--grid-item-body'>
											<p>{place.description}</p>
										</div>
									</Link>
								))}
							</div>
							{searchLoading ? (
								<Loader />
							) : loadMore ? (
								<button className='button--primary load-more' onClick={loadMorePlaces}>
									Voir plus de résultats
								</button>
							) : (
								noResult && (
									<>
										<p className='page-place-search--no-result page--title'>Aucun résultat pour "{query}"</p>
										{authState.firstName ? (
											<p className='page-place-search--no-result'>
												{authState.firstName}, êtes vous propriétaire d'un établissement ?<br />
												Si c'est le cas, rendez-vous sur la page <Link to='my-account'>Mon compte</Link> pour créer une page dès aujourd’hui !<br />
												En créant votre page, celle-ci sera disponible dans cette recherche ou sur notre application.
											</p>
										) : (
											<p className='page-place-search--no-result'>
												Vous propriétaire d'un établissement ?<br />
												Si c'est le cas, <Link to='/login'>Connectez-vous</Link> ou <Link to='/register'>Rejoignez la communauté</Link> pour créer votre page
												dès aujourd’hui !<br />
												En créant votre page, celle-ci sera disponible dans cette recherche ou sur notre application.
											</p>
										)}
									</>
								)
							)}
						</div>
					</Section>
				</>
			)}
		</Page>
	);
};

export default PlaceSearch;

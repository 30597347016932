import Page from '../components/Page';
import Section from '../components/Section';
import { useCookiesContext } from '../context/CookiesContext';

const PrivacyPolicy = () => {
	const { cookiesDispatch } = useCookiesContext();

	return (
		<Page pageClass='privacy-policy' title='Politique de confidentialité'>
			<Section color='default' className='page-privacy-policy-introduction'>
				<div className='col-xs-4 col-sm-12'>
					<h1>Politique de confidentialité</h1>
					<p>Dernière mise à jour : 31 Décembre 2022</p>
					<p>
						La présente politique de confidentialité décrit nos politiques et procédures en matière de collecte, d'utilisation et de divulgation de vos
						informations lorsque vous utilisez le service et vous informe de vos droits en matière de confidentialité et de la manière dont la loi vous protège.
					</p>
					<p>
						Nous utilisons vos données personnelles pour fournir et améliorer le Service. En utilisant le Service, vous acceptez la collecte et l'utilisation
						des informations conformément à la présente politique de confidentialité.
					</p>
				</div>
			</Section>
			<Section color='default' className='page-privacy-policy-interpretation'>
				<div className='col-xs-4 col-sm-12'>
					<h2>Interprétation et définitions</h2>
					<h3>Interprétation</h3>
					<p>
						Les mots dont la lettre initiale est en majuscule ont des significations définies dans les conditions suivantes. Les définitions suivantes ont le
						même sens, qu'elles apparaissent au singulier ou au pluriel.
					</p>
					<h3>Définitions</h3>
					<p>Aux fins de la présente politique de confidentialité :</p>
					<ul>
						<li>
							<b>Compte</b> désigne un compte unique créé pour vous afin d'accéder à notre service ou à certaines parties de notre service.
						</li>
						<li>
							<b>Affilié</b> signifie une entité qui contrôle, est contrôlée par ou est sous contrôle commun avec une partie, où le "contrôle" signifie la
							propriété de 50% ou plus des actions, de la participation au capital ou d'autres titres donnant droit de vote pour l'élection des administrateurs
							ou d'autres pouvoirs de gestion.
						</li>
						<li>
							L'<b>application</b> désigne le programme logiciel fourni par la société et téléchargé par vous sur tout appareil électronique, nommé
							Social&nbsp;O&nbsp;Matic.
						</li>
						<li>
							<b>La société</b> (désignée par "la société", "nous", "notre" ou "nos" dans le présent accord) fait référence à Social&nbsp;O&nbsp;Matic.
							<br />
							Aux fins du GDPR, la Société est le contrôleur des données.
						</li>
						<li>
							<b>Le pays</b> se réfère à : Suisse
						</li>
						<li>
							<b>Le Contrôleur des données</b>, aux fins du GDPR (Règlement général sur la protection des données), désigne la Société comme la personne morale
							qui, seule ou conjointement avec d'autres, détermine les finalités et les moyens du traitement des Données personnelles.
						</li>
						<li>
							<b>Dispositif</b> désigne tout appareil permettant d'accéder au Service, tel qu'un ordinateur, un téléphone portable ou une tablette numérique.
						</li>
						<li>
							Les <b>données personnelles</b> sont toutes les informations qui se rapportent à une personne identifiée ou identifiable. Aux fins du GDPR, les
							Données personnelles désignent toute information Vous concernant telle qu'un nom, un numéro d'identification, des données de localisation, un
							identifiant en ligne ou à un ou plusieurs facteurs spécifiques à l'identité physique, physiologique, génétique, mentale, économique, culturelle ou
							sociale.
						</li>
						<li>
							Le <b>service</b> fait référence à l'application.
						</li>
						<li>
							<b>Prestataire de services</b> désigne toute personne physique ou morale qui traite les données pour le compte de la Société. Il s'agit de
							sociétés tierces ou de personnes employées par la Société pour faciliter le Service, pour fournir le Service au nom de la Société, pour effectuer
							des services liés au Service ou pour aider la Société à analyser la façon dont le Service est utilisé. Aux fins du GDPR, les fournisseurs de
							services sont considérés comme des processeurs de données.
						</li>
						<li>
							<b>Les données d'utilisation</b> désignent les données collectées automatiquement, soit générées par l'utilisation du service, soit provenant de
							l'infrastructure du service elle-même (par exemple, la durée de la visite d'une page).
						</li>
						<li>
							<b>Vous</b> désigne la personne qui accède au Service ou l'utilise, ou la société ou autre entité juridique au nom de laquelle cette personne
							accède au Service ou l'utilise, selon le cas.
							<br />
							Dans le cadre du RGPD (Règlement général sur la protection des données), Vous pouvez être désigné comme la Personne concernée ou comme
							l'Utilisateur car vous êtes la personne physique qui utilise le Service.
						</li>
					</ul>
				</div>
			</Section>
			<Section color='default' className='page-privacy-policy-collect'>
				<div className='col-xs-4 col-sm-12'>
					<h2>Collecte et utilisation de vos données personnelles</h2>
					<h3>Types de données collectées</h3>
					<h4>Données personnelles</h4>
					<p>
						Lors de l'utilisation de notre service, nous pouvons vous demander de nous fournir certaines informations personnellement identifiables qui peuvent
						être utilisées pour vous contacter ou vous identifier. Les informations personnellement identifiables peuvent inclure, mais ne sont pas limitées à :
					</p>
					<ul>
						<li>Adresse électronique</li>
						<li>Prénom et nom de famille</li>
						<li>Données d'utilisation</li>
					</ul>
					<h4>Données d'utilisation</h4>
					<p>Les données d'utilisation sont collectées automatiquement lors de l'utilisation du service.</p>
					<p>
						Les Données d'utilisation peuvent inclure des informations telles que l'adresse de protocole Internet (par exemple, l'adresse IP) de votre Appareil,
						le type de navigateur, la version du navigateur, les pages de notre Service que vous visitez, l'heure et la date de votre visite, le temps passé sur
						ces pages, les identifiants uniques de l'appareil et d'autres données de diagnostic.
					</p>
					<p>
						Lorsque vous accédez au Service par ou via un appareil mobile, nous pouvons recueillir automatiquement certaines informations, y compris, mais sans
						s'y limiter, le type d'appareil mobile que vous utilisez, l'identifiant unique de votre appareil mobile, l'adresse IP de votre appareil mobile,
						votre système d'exploitation mobile, le type de navigateur Internet mobile que vous utilisez, les identifiants uniques de l'appareil et d'autres
						données de diagnostic.
					</p>
					<p>
						Nous pouvons également recueillir les informations que votre navigateur envoie lorsque vous visitez notre service ou lorsque vous accédez au service
						par ou via un appareil mobile.
					</p>
					<h4>Informations collectées lors de l'utilisation de l'application</h4>
					<p>
						Lors de l'utilisation de notre application, afin de fournir des fonctionnalités de notre application, nous pouvons collecter, avec votre
						autorisation préalable :
					</p>
					<ul>
						<li>Informations concernant votre localisation</li>
					</ul>
					<p>
						Nous utilisons ces informations pour fournir des fonctionnalités de Notre Service, pour améliorer et personnaliser Notre Service. Les informations
						peuvent être téléchargées sur les serveurs de la Société et/ou sur le serveur d'un fournisseur de services ou elles peuvent être simplement stockées
						sur Votre appareil.
					</p>
					<p>Vous pouvez activer ou désactiver l'accès à ces informations à tout moment, via les paramètres de votre appareil.</p>
					<h3>Utilisation de vos données personnelles</h3>
					<p>La société peut utiliser les données personnelles aux fins suivantes :</p>
					<ul>
						<li>
							<b>Pour fournir et maintenir notre service</b>, y compris pour surveiller l'utilisation de notre service.
						</li>
						<li>
							<b>Pour gérer votre compte</b> : pour gérer votre inscription en tant qu'utilisateur du Service. Les Données Personnelles que vous fournissez
							peuvent vous donner accès à différentes fonctionnalités du Service qui vous sont accessibles en tant qu'utilisateur enregistré.
						</li>
						<li>
							<b>Pour l'exécution d'un contrat</b> : l'élaboration, le respect et l'engagement du contrat d'achat des produits, articles ou services que Vous
							avez achetés ou de tout autre contrat avec Nous par le biais du Service.
						</li>
						<li>
							<b>Vous contacter</b> : Vous contacter par courriel, appels téléphoniques, SMS ou autres formes équivalentes de communication électronique, telles
							que les notifications push d'une application mobile concernant les mises à jour ou les communications informatives liées aux fonctionnalités,
							produits ou services contractuels, y compris les mises à jour de sécurité, lorsque cela est nécessaire ou raisonnable pour leur mise en œuvre.
						</li>
						<li>
							<b>Vous fournir</b> des nouvelles, des offres spéciales et des informations générales sur d'autres biens, services et événements que nous
							proposons et qui sont similaires à ceux que vous avez déjà achetés ou sur lesquels vous vous êtes renseigné, à moins que vous n'ayez choisi de ne
							pas recevoir ces informations.
						</li>
						<li>
							<b>Gérer vos demandes</b> : Pour assister et gérer les demandes que vous nous adressez.
						</li>
						<li>
							<b>Pour les transferts d'entreprise</b> : Nous pouvons utiliser Vos informations pour évaluer ou mener une fusion, un désinvestissement, une
							restructuration, une réorganisation, une dissolution ou toute autre vente ou transfert de tout ou partie de Nos actifs, que ce soit dans le cadre
							d'une poursuite d'activité ou d'une faillite, d'une liquidation ou d'une procédure similaire, dans laquelle les Données personnelles que Nous
							détenons sur les utilisateurs de nos Services font partie des actifs transférés.
						</li>
						<li>
							<b>À d'autres fins</b> : Nous pouvons utiliser vos informations à d'autres fins, telles que l'analyse des données, l'identification des tendances
							d'utilisation, la détermination de l'efficacité de nos campagnes promotionnelles et l'évaluation et l'amélioration de notre service, de nos
							produits, de nos services, du marketing et de votre expérience.
						</li>
					</ul>
					<p>Nous pouvons partager vos informations personnelles dans les situations suivantes :</p>
					<ul>
						<li>
							<b>Avec les fournisseurs de services</b> : Nous pouvons partager vos informations personnelles avec des fournisseurs de services afin de
							surveiller et d'analyser l'utilisation de notre service, de vous contacter.
						</li>
						<li>
							<b>Pour les transferts d'entreprise</b> : Nous pouvons partager ou transférer vos informations personnelles dans le cadre ou pendant les
							négociations d'une fusion, d'une vente d'actifs de la société, d'un financement ou de l'acquisition de tout ou partie de nos activités par une
							autre société.
						</li>
						<li>
							<b>Avec les sociétés affiliées</b> : Nous pouvons partager vos informations avec nos sociétés affiliées, auquel cas nous demanderons à ces
							sociétés affiliées de respecter la présente politique de confidentialité. Les sociétés affiliées comprennent notre société mère et toutes les
							autres filiales, partenaires de coentreprises ou autres sociétés que nous contrôlons ou qui sont sous contrôle commun avec nous.
						</li>
						<li>
							<b>Avec des partenaires commerciaux</b> : Nous pouvons partager vos informations avec nos partenaires commerciaux pour vous offrir certains
							produits, services ou promotions.
						</li>
						<li>
							<b>Avec d'autres utilisateurs</b> : lorsque vous partagez des informations personnelles ou interagissez de toute autre manière dans les zones
							publiques avec d'autres utilisateurs, ces informations peuvent être vues par tous les utilisateurs et peuvent être diffusées publiquement à
							l'extérieur.
						</li>
						<li>
							<b>Avec votre consentement</b> : Nous pouvons divulguer vos informations personnelles à toute autre fin avec votre consentement.
						</li>
					</ul>
					<h3>Conservation de vos données personnelles</h3>
					<p>
						La Société ne conservera Vos Données personnelles que pendant la durée nécessaire aux fins énoncées dans la présente Politique de confidentialité.
						Nous conserverons et utiliserons Vos Données personnelles dans la mesure nécessaire pour nous conformer à nos obligations légales (par exemple, si
						nous sommes tenus de conserver vos données pour nous conformer aux lois applicables), résoudre les litiges et appliquer nos accords et politiques
						juridiques.
					</p>
					<p>
						La Société conservera également les Données d'utilisation à des fins d'analyse interne. Les Données d'utilisation sont généralement conservées
						pendant une période plus courte, sauf si ces données sont utilisées pour renforcer la sécurité ou améliorer la fonctionnalité de Notre Service, ou
						si Nous sommes légalement obligés de conserver ces données pendant des périodes plus longues.
					</p>
					<h3>Transfert de vos données personnelles</h3>
					<p>
						Vos informations, y compris les Données personnelles, sont traitées dans les bureaux d'exploitation de la Société et dans tout autre lieu où se
						trouvent les parties impliquées dans le traitement. Cela signifie que ces informations peuvent être transférées vers - et conservées sur - des
						ordinateurs situés en dehors de Votre état, province, pays ou autre juridiction gouvernementale où les lois de protection des données peuvent
						différer de celles de Votre juridiction.
					</p>
					<p>
						Votre consentement à la présente politique de confidentialité, suivi de votre soumission de ces informations, représente votre accord à ce
						transfert.
					</p>
					<p>
						La Société prendra toutes les mesures raisonnablement nécessaires pour s'assurer que Vos données sont traitées en toute sécurité et conformément à
						la présente politique de confidentialité et aucun transfert de Vos données personnelles n'aura lieu vers une organisation ou un pays à moins qu'il
						n'y ait des contrôles adéquats en place, y compris la sécurité de Vos données et autres informations personnelles.
					</p>
					<h3>Divulgation de vos données personnelles</h3>
					<h4>Transactions commerciales</h4>
					<p>
						Si la société est impliquée dans une fusion, une acquisition ou une vente d'actifs, vos données personnelles peuvent être transférées. Nous
						fournirons un avis avant que Vos Données personnelles ne soient transférées et ne soient soumises à une Politique de confidentialité différente.
					</p>
					<h4>Application de la loi</h4>
					<p>
						Dans certaines circonstances, la Société peut être tenue de divulguer Vos Données personnelles si la loi l'exige ou en réponse à des demandes
						valables des autorités publiques (par exemple, un tribunal ou une agence gouvernementale).
					</p>
					<h4>Autres exigences légales</h4>
					<p>La Société peut divulguer vos données personnelles si elle croit de bonne foi qu'une telle action est nécessaire pour :</p>
					<ul>
						<li>Se conformer à une obligation légale</li>
						<li>Protéger et défendre les droits ou les biens de la société</li>
						<li>Prévenir ou enquêter sur d'éventuels actes répréhensibles en rapport avec le service</li>
						<li>Protéger la sécurité personnelle des utilisateurs du service ou du public</li>
						<li>Protéger contre la responsabilité juridique</li>
					</ul>
					<h3>Sécurité de vos données personnelles</h3>
					<p>
						La sécurité de vos données personnelles est importante pour Nous, mais n'oubliez pas qu'aucune méthode de transmission sur Internet ou de stockage
						électronique n'est sûre à 100 %. Bien que Nous nous efforcions d'utiliser des moyens commercialement acceptables pour protéger Vos Données
						Personnelles, Nous ne pouvons pas garantir leur sécurité absolue.
					</p>
				</div>
			</Section>
			<Section color='default' className='page-privacy-policy-rgpd'>
				<div className='col-xs-4 col-sm-12'>
					<h2>Confidentialité GDPR</h2>
					<h3>Base juridique du traitement des données personnelles en vertu du GDPR</h3>
					<p>Nous pouvons traiter les données personnelles dans les conditions suivantes :</p>
					<ul>
						<li>
							<b>Consentement</b> : Vous avez donné votre consentement au traitement des Données personnelles pour une ou plusieurs finalités spécifiques.
						</li>
						<li>
							<b>Exécution d'un contrat</b> : la fourniture de données à caractère personnel est nécessaire pour l'exécution d'un contrat avec vous et/ou pour
							toute obligation précontractuelle.
						</li>
						<li>
							<b>Obligations légales</b> : Le traitement des Données personnelles est nécessaire au respect d'une obligation légale à laquelle la Société est
							soumise.
						</li>
						<li>
							<b>Intérêts vitaux</b> : Le traitement des Données personnelles est nécessaire pour protéger Vos intérêts vitaux ou ceux d'une autre personne
							physique.
						</li>
						<li>
							<b>Intérêts publics</b> : Le traitement des Données personnelles est lié à une tâche effectuée dans l'intérêt public ou dans l'exercice de
							l'autorité officielle dont est investie la Société.
						</li>
						<li>
							<b>Intérêts légitimes</b> : Le traitement des Données personnelles est nécessaire aux fins des intérêts légitimes poursuivis par la Société.
						</li>
					</ul>
					<p>
						Dans tous les cas, la Société aidera volontiers à clarifier la base juridique spécifique qui s'applique au traitement, et en particulier si la
						fourniture de données personnelles est une exigence légale ou contractuelle, ou une exigence nécessaire pour conclure un contrat.
					</p>
					<h3>Vos droits en vertu du GDPR</h3>
					<p>La Société s'engage à respecter la confidentialité de Vos Données Personnelles et à Vous garantir l'exercice de Vos droits.</p>
					<p>En vertu de la présente politique de confidentialité, et de la loi si vous vous trouvez dans l'Union européenne, vous avez le droit de :</p>
					<ul>
						<li>
							<b>Demander l'accès à vos données personnelles</b>. Le droit d'accéder, de mettre à jour ou de supprimer les informations que Nous possédons sur
							Vous. Dans la mesure du possible, vous pouvez accéder, mettre à jour ou demander la suppression de vos données personnelles directement dans la
							section des paramètres de votre compte. Si vous n'êtes pas en mesure d'effectuer ces actions vous-même, veuillez nous contacter pour vous aider.
							Cela vous permet également de recevoir une copie des données personnelles que nous détenons à votre sujet.
						</li>
						<li>
							<b>Demander la correction des données personnelles que nous détenons à votre sujet</b>. Vous avez le droit de faire corriger toute information
							incomplète ou inexacte que nous détenons à votre sujet.
						</li>
						<li>
							<b>S'opposer au traitement de Vos Données Personnelles</b>. Ce droit existe lorsque Nous nous appuyons sur un intérêt légitime comme base
							juridique de Notre traitement et qu'il y a quelque chose dans Votre situation particulière, qui Vous pousse à vous opposer à notre traitement de
							Vos Données personnelles pour ce motif. Vous avez également le droit de vous opposer lorsque Nous traitons vos données personnelles à des fins de
							marketing direct.
						</li>
						<li>
							<b>Demander l'effacement de vos données personnelles</b>. Vous avez le droit de Nous demander d'effacer ou de supprimer des Données Personnelles
							lorsqu'il n'y a pas de raison valable pour Nous de continuer à les traiter.
						</li>
						<li>
							<b>Demander le transfert de vos données personnelles</b>. Nous vous fournirons, ou à un tiers que vous aurez choisi, vos données personnelles dans
							un format structuré, couramment utilisé et lisible par machine. Veuillez noter que ce droit ne s'applique qu'aux informations automatisées que
							vous avez initialement consenties à ce que nous utilisions ou lorsque nous avons utilisé ces informations pour exécuter un contrat avec vous.
						</li>
						<li>
							<b>Retirer votre consentement</b>. Vous avez le droit de retirer votre consentement à l'utilisation de vos données personnelles. Si vous retirez
							votre consentement, nous pourrions ne pas être en mesure de vous donner accès à certaines fonctionnalités spécifiques du service.
						</li>
					</ul>
					<h3>Exercice de vos droits en matière de protection des données dans le cadre du GDPR</h3>
					<p>
						Vous pouvez exercer vos droits d'accès, de rectification, d'annulation et d'opposition en nous contactant. Veuillez noter que nous pouvons vous
						demander de vérifier votre identité avant de répondre à ces demandes. Si vous faites une demande, nous ferons de notre mieux pour vous répondre dans
						les meilleurs délais.
					</p>
					<p>
						Vous avez le droit de vous plaindre auprès d'une autorité de protection des données au sujet de Notre collecte et utilisation de Vos données
						personnelles. Pour plus d'informations, si Vous êtes dans l'Espace économique européen (EEE), veuillez contacter Votre autorité locale de protection
						des données dans l'EEE.
					</p>
				</div>
			</Section>
			<Section color='default' className='page-privacy-policy-children'>
				<div className='col-xs-4 col-sm-12'>
					<h2>La vie privée des enfants</h2>
					<p>
						Notre Service ne s'adresse pas aux personnes âgées de moins de 18 ans. Nous ne recueillons pas sciemment d'informations personnellement
						identifiables auprès de personnes âgées de moins de 18 ans. Si vous êtes un parent ou un tuteur et que vous savez que votre enfant nous a fourni des
						données personnelles, veuillez nous contacter. Si Nous nous rendons compte que Nous avons collecté des données personnelles auprès de personnes
						âgées de moins de 18 ans sans vérification du consentement parental, Nous prenons des mesures pour supprimer ces informations de Nos serveurs.
					</p>
					<p>
						Si nous devons nous appuyer sur le consentement comme base juridique pour traiter vos informations et que votre pays exige le consentement d'un
						parent, nous pouvons exiger le consentement de votre parent avant de collecter et d'utiliser ces informations.
					</p>
				</div>
			</Section>
			<Section color='default' className='page-privacy-policy-web'>
				<div className='col-xs-4 col-sm-12'>
					<h2>Outils de statistiques</h2>
					<p>
						Notre Service peut utiliser des outils de statistiques (Uniquement sur notre site internet) pour suivre les tendances et permettre de vous proposer
						de novelles fonctionnalité adapté à la demande.
					</p>
					<p>
						Sachez que ces solutions utilisent des cookies pour permettre d'identifier votre session. Pour ce faire nous Utilisons les solutions suivantes :
					</p>
					<ul>
						<li>
							Matomo - <a href='https://matomo.org/'>https://matomo.org/</a> (Hébergé sur nos serveurs Suisses)
						</li>
					</ul>
					<p>
						Pour en savoir plus sur les cookies, vous pouvez vous rendre sur cette page comportant une définition:{' '}
						<a href='https://fr.wikipedia.org/wiki/Cookie_(informatique)' target='_blank' rel='noreferrer'>
							Cookie Informatique (Wikipedia)
						</a>
					</p>
					<p>Notez que vous pouvez à tout moment revenir sur votre choix à l'aide de ce bouton :</p>
					<button
						className='button--primary'
						onClick={() => {
							cookiesDispatch({ type: 'RESET' });
						}}>
						Réinitialiser mon choix
					</button>
					<p>
						Nous n'avons aucun contrôle et n'assumons aucune responsabilité quant au contenu, aux politiques de confidentialité ou aux pratiques de tout site ou
						service tiers.
					</p>
				</div>
			</Section>
			<Section color='default' className='page-privacy-policy-web'>
				<div className='col-xs-4 col-sm-12'>
					<h2>Liens vers d'autres sites web</h2>
					<p>
						Notre Service peut contenir des liens vers d'autres sites web qui ne sont pas exploités par Nous. Si vous cliquez sur un lien tiers, vous serez
						dirigé vers le site de ce tiers. Nous vous conseillons vivement de consulter la politique de confidentialité de chaque site que vous visitez.
					</p>
					<p>
						Nous n'avons aucun contrôle et n'assumons aucune responsabilité quant au contenu, aux politiques de confidentialité ou aux pratiques de tout site ou
						service tiers.
					</p>
				</div>
			</Section>
			<Section color='default' className='page-privacy-policy-edit'>
				<div className='col-xs-4 col-sm-12'>
					<h2>Modifications de la présente politique de confidentialité</h2>
					<p>
						Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous vous informerons de toute modification en publiant la nouvelle
						politique de confidentialité sur cette page.
					</p>
					<p>
						Nous vous en informerons par e-mail et/ou par un avis bien visible sur notre service, avant que la modification ne prenne effet et nous mettrons à
						jour la date de " dernière mise à jour " en haut de la présente politique de confidentialité.
					</p>
					<p>
						Nous vous conseillons de consulter régulièrement cette politique de confidentialité pour prendre connaissance des éventuelles modifications. Les
						modifications apportées à cette politique de confidentialité entrent en vigueur lorsqu'elles sont publiées sur cette page.
					</p>
				</div>
			</Section>
			<Section color='default' className='page-privacy-policy-contact'>
				<div className='col-xs-4 col-sm-12'>
					<h2>Nous contacter</h2>
					<p>Si vous avez des questions sur cette politique de confidentialité, vous pouvez nous contacter :</p>
					<ul>
						<li>
							Par courrier électronique : <a href='mailto:contact@social-o-matic.com'>contact@social-o-matic.com</a>
						</li>
					</ul>
				</div>
			</Section>
		</Page>
	);
};

export default PrivacyPolicy;

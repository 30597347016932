export const get_app_url = (): string => {
    if(process.env.REACT_APP_APP_URL) {
        return process.env.REACT_APP_APP_URL;
    } else {
        throw new Error('APP_URL not found');
    }
}

/**
 * Function used to get the client id from .env vars
 * @returns The client id
 */
export const get_client_id = (): string => {
    if(process.env.REACT_APP_CLIENT_ID) {
        return process.env.REACT_APP_CLIENT_ID;
    } else {
        throw new Error('CLIENT_ID not found');
    }
}

/**
 * Function used to get the client secret from .env vars
 * @returns The client secret
 */
export const get_client_secret = (): string => {
    if(process.env.REACT_APP_CLIENT_SECRET) {
        return process.env.REACT_APP_CLIENT_SECRET;
    } else {
        throw new Error('CLIENT_SECRET not found');
    }
}
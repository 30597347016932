import axios, { AxiosResponse } from 'axios';
import { USER_URL } from '../../endpoints';
import { User } from '../../models/user';
import { NETWORK_ERROR, SERVER_ERROR, UNAUTHORIZED } from '../errors';
import { headerGenerator } from '../headersGenerator';

/**
 * Function used to retrieve current user details
 * @param authToken The user barer token
 * @returns The user object
 */
export const getCurrentUser = async (authToken: string): Promise<User> => {
	try {
		const response: AxiosResponse<User> = await axios.get(`${USER_URL}`, headerGenerator(authToken));
		return response.data;
	} catch (error: any) {
		if (error.response) {
			if (error.response.status === 401) {
				throw UNAUTHORIZED;
			} else {
				throw SERVER_ERROR;
			}
		} else {
			throw NETWORK_ERROR;
		}
	}
};

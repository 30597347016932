import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

interface RestrictedAreaProps {
	children?: React.ReactNode;
	needAuth: boolean;
}

const RestrictedArea = (props: RestrictedAreaProps) => {
	const { authState } = useAuth();

	if (props.needAuth && !authState.token) {
		return <Navigate to='/login' replace />;
	} else if (!props.needAuth && authState.token) {
		return <Navigate to='/my-account' replace />;
	} else {
		return <>{props.children}</>;
	}
};

export default RestrictedArea;

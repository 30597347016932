import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface TextWithImageProps {
	title?: string;
	content: ReactNode;
	side: ReactNode;
	cta?: {
		text: string;
		link: string;
		local: boolean;
		color: 'primary' | 'white' | 'black';
	};
	reverse: boolean;
	large: boolean;
}

const TextWithImage = (props: TextWithImageProps) => {
	const { title, content, side, cta, reverse, large } = props;

	return (
		<section className='text-with-image'>
			<div className='container'>
				<div className={`row ${reverse ? 'text-with-image--reverse' : ''}`}>
					<div className={`col-xs-4 col-sm-12 col-md-5 text-with-image--side`}>
						<div className='text-with-image--side-content'>{side}</div>
					</div>
					<div className={`col-xs-4 col-sm-12 col-md-7 text-with-image--content${large ? ' text-with-image--content-large' : ''}`}>
						{title && <h3 className='text-with-image--title'>{title}</h3>}
						{content}
						{cta && {
							...(cta.local ? (
								<Link to={cta.link} className={`button--${cta.color}`}>
									{cta.text}
								</Link>
							) : (
								<a href={cta.link} target='_blank' rel='noreferrer' className={`button--${cta.color}`}>
									{cta.text}
								</a>
							)),
						}}
					</div>
				</div>
			</div>
		</section>
	);
};

export default TextWithImage;

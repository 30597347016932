import axios, { AxiosResponse } from 'axios';
import { checkEmail, checkPassword } from '../../check';
import {
	CHECK_EMAIL,
	LOGIN_URL,
	LOGOUT_URL,
	REGISTER_URL,
	RESET_PASSWORD_URL,
	USER_DELETE_URL,
	USER_UPDATE_URL,
	VERIFICATION_CODE_CHECK_URL,
	VERIFICATION_CODE_CREATE_URL,
} from '../../endpoints';
import { Barer } from '../../models/barer';
import { RegisterError } from '../../models/registerError';
import { ResponseManager } from '../../models/responseManager';
import { User } from '../../models/user';
import { VerificationCode } from '../../models/verificationCode';
import {
	ALREADY_SENDED,
	BAD_CODE,
	BIO_TOO_LONG,
	CODE_NOT_VALID,
	EMAIL_NOT_VALID,
	EMAIL_REJECTED,
	MISSING_VALUES,
	NAME_TOO_SHORT,
	NETWORK_ERROR,
	PASSWORD_NOT_VALID,
	SERVER_ERROR,
	SERVER_REJECTED,
	SURNAME_TOO_SHORT,
	UNAUTHORIZED,
	USERNAME_REJECTED,
	USERNAME_TOO_SHORT,
	USER_NOT_FOUND,
} from '../errors';
import { headerGenerator } from '../headersGenerator';
import { get_client_id, get_client_secret } from '../vars';

/**
 * Function used to login to the server
 * @param username The username of the user
 * @param password The password of the user
 * @returns The barer token
 */
export const login = async (username: string, password: string): Promise<Barer> => {
	if (username && password) {
		try {
			const formData = new FormData();
			formData.append('grant_type', 'password');
			formData.append('username', username);
			formData.append('password', password);
			formData.append('client_id', get_client_id());
			formData.append('client_secret', get_client_secret());

			const response: AxiosResponse<Barer> = await axios.post(`${LOGIN_URL}`, formData, headerGenerator());
			const barer: Barer = response.data;
			localStorage.setItem('user_settings', JSON.stringify(barer));
			return response.data;
		} catch (error: any) {
			if (error.response) {
				console.log(error.response);

				if (error.response.status === 400) {
					throw USER_NOT_FOUND;
				} else {
					throw SERVER_ERROR;
				}
			} else {
				throw NETWORK_ERROR;
			}
		}
	} else {
		throw MISSING_VALUES;
	}
};

/**
 * A function used to logout user from the server
 * @param authToken The user barer token
 */
export const logout = async (authToken: string): Promise<void> => {
	try {
		await axios.delete(LOGOUT_URL, headerGenerator(authToken));
		localStorage.removeItem('user_settings');
	} catch (error: any) {
		if (error.response) {
			throw SERVER_ERROR;
		} else {
			throw NETWORK_ERROR;
		}
	}
};

/**
 * A function used to create a new user
 * @param username The username of the user
 * @param password The password of the user
 * @param email The email of the user
 * @param firstName The name of the user
 * @param lastName The surname of the user
 * @param bio The bio of the user
 * @returns The user response manager
 */
export const register = async (
	username: string,
	password: string,
	email: string,
	firstName: string,
	lastName: string,
	bio?: string
): Promise<ResponseManager<User>> => {
	//Check if we have mandatory values
	if (username && password && email && firstName && lastName) {
		let errors: RegisterError = {};

		//Check fields
		if (firstName.length < 2) {
			errors.name = NAME_TOO_SHORT;
		}
		if (lastName.length < 2) {
			errors.surname = NAME_TOO_SHORT;
		}
		if (username.length < 3) {
			errors.username = USERNAME_TOO_SHORT;
		}
		if (bio && bio.length > 500) {
			errors.bio = BIO_TOO_LONG;
		}
		if (!checkPassword(password)) {
			errors.password = PASSWORD_NOT_VALID;
		}
		if (!checkEmail(email)) {
			errors.email = EMAIL_NOT_VALID;
		}

		//Check if we have errors
		if (Object.keys(errors).length > 0) {
			throw errors;
		}

		//Do the request
		try {
			const formData = new FormData();
			formData.append('client_id', get_client_id());
			formData.append('client_secret', get_client_secret());
			formData.append('username', username);
			formData.append('password', password);
			formData.append('email', email);
			formData.append('first_name', firstName);
			formData.append('last_name', lastName);
			if (bio) {
				formData.append('bio', bio);
			}

			const response: AxiosResponse<ResponseManager<User>> = await axios.post(`${REGISTER_URL}`, formData, headerGenerator());

			return response.data;
		} catch (error: any) {
			if (error.response) {
				const data = error.response.data;
				if (data.errors.username) {
					errors.username = USERNAME_REJECTED;
				}
				if (data.errors.email) {
					errors.email = EMAIL_NOT_VALID;
				}
				if (!errors.username && !errors.email) {
					errors.other = SERVER_REJECTED;
				}
			} else {
				errors.other = NETWORK_ERROR;
			}
			throw errors;
		}
	} else {
		throw MISSING_VALUES;
	}
};

/**
 * Function used to reset a user password from a code
 * @param email The email of the user
 * @param code The verification code used to reset the password
 * @param password The new password
 * @returns void
 */
export const reset_password = async (email: string, code: string, password: string): Promise<void> => {
	if (email && code && password) {
		//Check code
		try {
			const checkCode = parseInt(code);
			if (checkCode < 100000 || checkCode > 999999) {
				throw CODE_NOT_VALID;
			}
		} catch (error: any) {
			throw CODE_NOT_VALID;
		}

		//Check password
		if (!checkPassword(password)) {
			throw PASSWORD_NOT_VALID;
		}

		//Do the request
		try {
			const formData = new FormData();
			formData.append('email', email);
			formData.append('verification_code', code);
			formData.append('password', password);
			formData.append('client_id', get_client_id());
			formData.append('client_secret', get_client_secret());
			await axios.post(`${RESET_PASSWORD_URL}`, formData, headerGenerator());
			return;
		} catch (error: any) {
			if (error.response) {
				if (error.response.status === 403) {
					throw BAD_CODE;
				} else if (error.response.status === 422) {
					throw CODE_NOT_VALID;
				} else {
					throw SERVER_ERROR;
				}
			} else {
				throw NETWORK_ERROR;
			}
		}
	} else {
		throw MISSING_VALUES;
	}
};

/**
 * Function used to refresh the barer token from a refresh token
 * @param token The user refresh token
 * @returns A new set of barer token
 */
export const refresh_token = async (token: string): Promise<Barer> => {
	try {
		const formData = new FormData();
		formData.append('client_id', get_client_id());
		formData.append('client_secret', get_client_secret());
		formData.append('grant_type', 'refresh_token');
		formData.append('refresh_token', token);
		const response: AxiosResponse<Barer> = await axios.post(`${LOGIN_URL}`, formData, headerGenerator());
		return response.data;
	} catch (error: any) {
		if (error.response) {
			if (error.response.status === 400) {
				throw UNAUTHORIZED;
			} else {
				throw SERVER_ERROR;
			}
		} else {
			throw NETWORK_ERROR;
		}
	}
};

/**
 * Function used to update a User
 * @param authToken The user barer token
 * @param name The last name
 * @param surname The first name
 * @param username The username
 * @param email The email
 * @param password The password
 * @param bio The biography
 * @param push_add_friend If user want push friend request update
 * @param push_invite If user want push invite update
 * @param push_invite_update If user want push invite edited update
 * @returns If operation is done
 */
export const update_user = async (
	authToken: string,
	name?: string,
	surname?: string,
	username?: string,
	email?: string,
	password?: string,
	bio?: string,
	push_add_friend?: boolean,
	push_invite?: boolean,
	push_invite_update?: boolean
): Promise<User | null> => {
	//Check if we have values
	if (
		name ||
		surname ||
		username ||
		email ||
		password ||
		bio ||
		push_add_friend !== undefined ||
		push_invite !== undefined ||
		push_invite_update !== undefined
	) {
		let errors: RegisterError = {};

		//Check if fields complete requirements
		if (name && name.length < 2) {
			errors.name = NAME_TOO_SHORT;
		}
		if (surname && surname.length < 2) {
			errors.surname = SURNAME_TOO_SHORT;
		}
		if (username && username.length < 3) {
			errors.username = USERNAME_TOO_SHORT;
		}
		if (email && !checkEmail(email)) {
			errors.email = EMAIL_NOT_VALID;
		}
		if (password && !checkPassword(password)) {
			errors.password = PASSWORD_NOT_VALID;
		}
		if (bio && bio.length > 500) {
			errors.bio = BIO_TOO_LONG;
		}

		//Check if we have errors
		if (errors.name || errors.surname || errors.email || errors.username || errors.password || errors.bio) {
			throw errors;
		}

		//Do the request
		try {
			//Prepare parameters
			const formData = new FormData();
			if (name) {
				formData.append('last_name', name);
			}
			if (surname) {
				formData.append('first_name', surname);
			}
			if (email) {
				formData.append('email', email);
			}
			if (username) {
				formData.append('username', username);
			}
			if (password) {
				formData.append('password', password);
			}
			if (bio) {
				formData.append('bio', bio);
			}
			if (push_add_friend !== undefined) {
				formData.append('push_add_friend', push_add_friend ? '1' : '0');
			}
			if (push_invite !== undefined) {
				formData.append('push_invite', push_invite ? '1' : '0');
			}
			if (push_invite_update !== undefined) {
				formData.append('push_invite_update', push_invite_update ? '1' : '0');
			}
			//Do the request
			const user: AxiosResponse<User> = await axios.post(USER_UPDATE_URL, formData, headerGenerator(authToken));
			return user.data;
		} catch (error: any) {
			const errors: RegisterError = {};
			if (error.response) {
				const data = error.response.data;
				if (data.errors.username) {
					errors.username = USERNAME_REJECTED;
				}
				if (data.errors.email) {
					errors.email = EMAIL_REJECTED;
				}
				if (!errors.username && !errors.email) {
					errors.other = SERVER_REJECTED;
				}
			} else {
				errors.other = NETWORK_ERROR;
			}

			throw errors;
		}
	} else {
		//No modifications
		return null;
	}
};

/**
 * Function used to send verification code
 * @param email
 * @param isPassword Set true if you want a code to reset a password or false to validate an email
 * @returns The status of request
 */
export const send_verification_code = async (email: string, isPassword: boolean): Promise<ResponseManager<VerificationCode>> => {
	//Check if e-mail is valid
	if (!checkEmail(email)) {
		throw EMAIL_NOT_VALID;
	}

	try {
		const formData = new FormData();
		formData.append('client_id', get_client_id());
		formData.append('client_secret', get_client_secret());
		formData.append('email', email);
		formData.append('isPassword', isPassword ? '1' : '0');

		const result: AxiosResponse<ResponseManager<VerificationCode>> = await axios.post(VERIFICATION_CODE_CREATE_URL, formData, headerGenerator());

		return result.data;
	} catch (error: any) {
		if (error.response) {
			if (error.response.status === 404) {
				throw EMAIL_REJECTED;
			} else if (error.response.status === 403) {
				throw ALREADY_SENDED;
			} else {
				throw SERVER_ERROR;
			}
		} else {
			throw NETWORK_ERROR;
		}
	}
};

/**
 * Function used to validate an email with a verification code
 * @param code
 * @param barer
 * @returns The request status
 */
export const validate_email = async (code: number, barer: string): Promise<ResponseManager<User>> => {
	try {
		const formData = new FormData();
		formData.append('verification_code', code.toString());

		const response: AxiosResponse<ResponseManager<User>> = await axios.post(CHECK_EMAIL, formData, {
			headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${barer}` },
		});

		return response.data;
	} catch (error: any) {
		if (error.response) {
			throw BAD_CODE;
		} else {
			throw NETWORK_ERROR;
		}
	}
};

/**
 * Function used to check if a code can be used to reset a password
 * @param code The verification code
 * @param email The e-mail account associated to the verification code request
 * @returns If verification code is valid
 */
export const validate_code = async (code: number, email: string): Promise<Boolean> => {
	//Check if e-mail is valid
	if (!checkEmail(email)) {
		throw EMAIL_NOT_VALID;
	}

	//Check if code is valid
	if (code.toString().length === 6) {
		try {
			const formData = new FormData();
			formData.append('client_id', get_client_id());
			formData.append('client_secret', get_client_secret());
			formData.append('email', email);
			formData.append('verification_code', code.toString());
			await axios.post(VERIFICATION_CODE_CHECK_URL, formData, headerGenerator());
			return true;
		} catch (error: any) {
			if (error.response) {
				if (error.response.status === 403) {
					throw BAD_CODE;
				} else {
					throw SERVER_ERROR;
				}
			} else {
				throw NETWORK_ERROR;
			}
		}
	} else {
		throw BAD_CODE;
	}
};

/**
 * A function used to delete a user and all his data saved on the server
 * @param authToken The user barer token
 * @param password The current password of the user
 * @returns If operation is done
 */
export const delete_user = async (authToken: string, password: string): Promise<boolean> => {
	try {
		const formData = new FormData();
		formData.append('password', password);
		await axios.post(USER_DELETE_URL, formData, headerGenerator(authToken));
		return true;
	} catch (error: any) {
		if (error.response) {
			if (error.response.status === 403) {
				throw UNAUTHORIZED;
			} else {
				throw SERVER_ERROR;
			}
		} else {
			throw NETWORK_ERROR;
		}
	}
};

import { Link } from 'react-router-dom';
import useWindowSize from '../hooks/useWindowSize';
import { Size } from '../tools/models/size';
import Page from '../components/Page';
import Lottie from 'react-lottie';
import RestrictedArea from '../components/RestrictedArea';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { login } from '../tools/server/auth/auth';
import { Barer } from '../tools/models/barer';
import { useState } from 'react';
import Notice from '../components/Notice';
import Loader from '../components/Loader';
import { NETWORK_ERROR, SERVER_ERROR, USER_NOT_FOUND } from '../tools/server/errors';
import { getCurrentUser } from '../tools/server/content/user';
import { User } from '../tools/models/user';
import { AuthAction, useAuth } from '../context/AuthContext';

const Login = () => {
	const windowSize: Size = useWindowSize();
	const { authDispatch } = useAuth();

	const validationSchema = Yup.object().shape({
		username: Yup.string().required('Obligatoire'),
		password: Yup.string().required('Obligatoire'),
	});

	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	return (
		<RestrictedArea needAuth={false}>
			<Page pageClass='login' title='Se connecter'>
				<section className='container page-login-content' style={{ minHeight: windowSize.height }}>
					<div className='row'>
						<div className='col-xs-4 col-sm-12 col-md-3 page-login-animation'>
							<Lottie
								options={{
									animationData: require('../assets/lottie/illustrations_social_friends.json'),
									loop: true,
								}}
							/>
						</div>
						<div className='col-xs-4 col-sm-12 col-md-1' />
						<div className='col-xs-4 col-sm-12 col-md-8 page-login-form'>
							<h1 className='page-login-title'>Se connecter au Social O Matic</h1>
							{errorMessage && (
								<Notice type='error'>
									<p>{errorMessage}</p>
								</Notice>
							)}
							{isLoading ? (
								<Loader />
							) : (
								<Formik
									initialValues={{
										username: '',
										password: '',
									}}
									validationSchema={validationSchema}
									onSubmit={(values) => {
										setErrorMessage(null);
										setIsLoading(true);
										login(values.username, values.password)
											.then((response: Barer) => {
												//Save tokens in local storage
												localStorage.setItem('token', response.access_token);
												localStorage.setItem('refreshToken', response.refresh_token);

												//Get current user details
												getCurrentUser(response.access_token)
													.then((user: User) => {
														//Set up auth context
														const data: AuthAction = {
															type: 'LOGIN',
															payload: {
																userId: user.id,
																username: user.username,
																firstName: user.firstName,
																lastName: user.lastName,
																email: user.email,
																token: response.access_token,
																bio: user.bio,
																createdAt: user.created_at,
																updatedAt: user.updated_at,
																emailValidatedAt: user.email_verified_at,
															},
														};
														setIsLoading(false);
														authDispatch(data);
													})
													.catch((error) => {
														setIsLoading(false);
														setErrorMessage('Une erreur est survenue lors de la récupération de votre profil. Veuillez recharger la page.');
													});
											})
											.catch((error) => {
												setIsLoading(false);
												switch (error) {
													case USER_NOT_FOUND:
														setErrorMessage("Veuillez vérifier votre nom d'utilisateur et votre mot de passe.");
														break;
													case SERVER_ERROR:
														setErrorMessage('Une erreur est survenue, veuillez réessayer plus tard.');
														break;
													case NETWORK_ERROR:
														setErrorMessage('Veuillez vérifier votre connexion internet.');
														break;
													default:
														setErrorMessage('Une erreur inconnue est survenue. Veuillez réessayer plus tard.');
														break;
												}
											});
									}}>
									<Form>
										<div className='form-group'>
											<label htmlFor='username'>Nom d'utilisateur / e-mail</label>
											<Field type='username' name='username' id='username' />
											<ErrorMessage name='username' component='div' className='form-error' />
										</div>
										<div className='form-group'>
											<label htmlFor='password'>Mot de passe</label>
											<Field type='password' name='password' id='password' />
											<ErrorMessage name='password' component='div' className='form-error' />
										</div>
										<div className='form-group form-group-submit'>
											<button type='submit' className='form-circular-button'>
												<i className='fa-solid fa-arrow-right'></i>
											</button>
											<Link to='/register' className='button--black'>
												Créer un compte
											</Link>
										</div>
										<div className='form-group'>
											<Link to='/forgot-password'>Mot de passe oublié</Link>
										</div>
									</Form>
								</Formik>
							)}
						</div>
					</div>
				</section>
			</Page>
		</RestrictedArea>
	);
};

export default Login;

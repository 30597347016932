import { ErrorMessage, Field, Form, Formik } from 'formik';
import Page from '../../../components/Page';
import * as Yup from 'yup';
import { useState } from 'react';
import { reset_password } from '../../../tools/server/auth/auth';
import { usePasswordContext } from '../../../context/PasswordContext';
import { useNavigate } from 'react-router-dom';
import { BAD_CODE, CODE_NOT_VALID } from '../../../tools/server/errors';
import Loader from '../../../components/Loader';
import Notice from '../../../components/Notice';

const SetNewPassword = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const navigate = useNavigate();
	const { passwordDispatch, passwordState } = usePasswordContext();
	const validationSchema = Yup.object().shape({
		password: Yup.string()
			.required('Ce champ est obligatoire')
			.min(8, 'Ce champ doit contenir au moins 8 caractères')
			.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\s\S]{8,}$/, 'Ce champ doit contenir au moins 1 majuscule, 1 minuscule et 1 chiffre'),
		passwordConfirm: Yup.string()
			.required('Ce champ est obligatoire')
			.oneOf([Yup.ref('password'), null], 'Les mots de passe ne correspondent pas'),
	});

	const handle = (values: any) => {
		setIsLoading(true);
		setError(null);
		reset_password(passwordState.email, passwordState.code, values.password)
			.then(() => {
				navigate('/login');
				passwordDispatch({ type: 'RESET' });
				setIsLoading(false);
			})
			.catch((error: string) => {
				switch (error) {
					case CODE_NOT_VALID: {
						passwordDispatch({ type: 'PREVIOUS_STEP' });
						break;
					}
					case BAD_CODE: {
						passwordDispatch({ type: 'PREVIOUS_STEP' });
						break;
					}
					default: {
						setError('Une erreur est survenue');
						break;
					}
				}
				setIsLoading(false);
			});
	};

	return (
		<Page pageClass='reset-password' title='Redéfinir le mot de passe'>
			<h1 className='page-reset-password-title'>Redéfinir le mot de passe</h1>
			{isLoading ? (
				<Loader />
			) : (
				<>
					{error && <Notice type='error'>{error}</Notice>}
					<Formik
						initialValues={{
							password: '',
							passwordConfirm: '',
						}}
						validationSchema={validationSchema}
						onSubmit={(values) => {
							handle(values);
						}}>
						<Form>
							<div className='form-group form-row'>
								<div className='form-col'>
									<div className='form-group'>
										<label htmlFor='password'>Mot de passe</label>
										<Field type='password' name='password' id='password' />
										<ErrorMessage name='password' component='div' className='form-error' />
									</div>
								</div>
								<div className='form-col'>
									<div className='form-group'>
										<label htmlFor='passwordConfirm'>Confirmer le mot de passe</label>
										<Field type='password' name='passwordConfirm' id='passwordConfirm' />
										<ErrorMessage name='passwordConfirm' component='div' className='form-error' />
									</div>
								</div>
							</div>
							<div className='form-group form-group-submit'>
								<button type='submit' className='form-circular-button'>
									<i className='fa-solid fa-arrow-right'></i>
								</button>
							</div>
						</Form>
					</Formik>
				</>
			)}
		</Page>
	);
};

export default SetNewPassword;

import { Context, createContext, ReactNode, useContext, useReducer } from 'react';
import { RegisterState } from '../tools/models/RegisterState';

const RegisterContext: Context<any> = createContext(undefined);

export type RegisterAction =
	| {
			type: 'USER_DETAILS';
			payload: {
				lastName: string;
				firstName: string;
				username: string;
				email: string;
				password: string;
				description: string;
			};
	  }
	| {
			type: 'RESET';
	  }
	| {
			type: 'NEXT_STEP';
	  }
	| {
			type: 'PREVIOUS_STEP';
	  };

interface RegisterProviderProps {
	children?: ReactNode;
}

const initialState: RegisterState = {
	lastName: '',
	firstName: '',
	username: '',
	email: '',
	password: '',
	description: '',
	step: 1,
};

function registerReducer(state: RegisterState, action: RegisterAction) {
	switch (action.type) {
		case 'USER_DETAILS':
			return {
				...state,
				lastName: action.payload.lastName,
				firstName: action.payload.firstName,
				username: action.payload.username,
				email: action.payload.email,
				password: action.payload.password,
				description: action.payload.description,
			};
		case 'RESET':
			return {
				...state,
				lastName: '',
				firstName: '',
				username: '',
				email: '',
				password: '',
				description: '',
				step: 1,
			};
		case 'NEXT_STEP':
			return {
				...state,
				step: state.step + 1,
			};
		case 'PREVIOUS_STEP':
			return {
				...state,
				step: state.step - 1,
			};
		default:
			throw new Error(`Unhandled action type for RegisterContext`);
	}
}

export function RegisterProvider({ children }: RegisterProviderProps) {
	const [registerState, registerDispatch] = useReducer(registerReducer, initialState);

	return <RegisterContext.Provider value={{ registerState, registerDispatch }}>{children}</RegisterContext.Provider>;
}

export function useRegister() {
	const context = useContext(RegisterContext);
	if (context === undefined) {
		throw new Error('Cannot find RegisterProvider');
	}
	return context;
}

import { RegisterProvider } from '../../context/RegisterContext';
import RegistrationDispatcher from './RegistrationDispatcher';

const Registration = () => {
	return (
		<RegisterProvider>
			<RegistrationDispatcher />
		</RegisterProvider>
	);
};

export default Registration;

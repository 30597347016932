import Lottie from 'react-lottie';

interface HeaderProps {
	title: string;
	subtitle?: string;
	image?: string;
	lottie?: string;
}

const Header = (props: HeaderProps) => {
	return (
		<section className='header'>
			<div className='container'>
				<div className='row'>
					<div className='col-xs-4 col-sm-12 col-md-7'>
						<h1 className='header-title'>{props.title}</h1>
						{props.subtitle && <h2 className='header-sub-title'>{props.subtitle}</h2>}
					</div>
					{props.image ? (
						<div className='col-xs-4 col-sm-12 col-md-5'>
							<img className='header-image' src={props.image} alt='' />
						</div>
					) : (
						props.lottie && (
							<div className='col-xs-4 col-sm-12 col-md-5'>
								<Lottie
									options={{
										rendererSettings: {
											className: 'header-image',
										},
										animationData: props.lottie,
										loop: true,
									}}
								/>
							</div>
						)
					)}
				</div>
			</div>
		</section>
	);
};

export default Header;

import apple from '../assets/logo/appstore.svg';
import google from '../assets/logo/google-play.png';
import mockup from '../assets/images/mockup/overview-SocialOMatic-phones.png';

const Downloads = () => {
	return (
		<section className='downloads'>
			<div className='container'>
				<div className='downloads--head'>
					<h3 className='downloads--head-title'>Convaincu·e ? Alors rendez-vous sur votre mobile !</h3>
					<p className='downloads--head-sub-title'>
						Rejoignez la communauté Social O Matic dès maintenant en vous inscrivant sur notre site ou sur notre application mobile.
					</p>
				</div>
				<nav className='downloads--links'>
					<ul className='downloads--links-list'>
						<li className='downloads--links-item'>
							<a href='https://apps.apple.com/fr/app/social-o-matic/id1541500000' className='downloads--links-link'>
								<img src={apple} alt='apple' className='downloads--links-img' />
							</a>
						</li>
						<li className='downloads--links-item'>
							<a href='https://play.google.com/store/apps/details?id=com.socialomatic' className='downloads--links-link'>
								<img src={google} alt='google' className='downloads--links-img' />
							</a>
						</li>
					</ul>
				</nav>
				<figure className='downloads--mockup'>
					<img src={mockup} alt='' aria-hidden={true} />
				</figure>
			</div>
		</section>
	);
};

export default Downloads;

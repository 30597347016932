import { Context, createContext, ReactNode, useContext, useReducer } from 'react';
import { PasswordState } from '../tools/models/PasswordState';

const PasswordContext: Context<any> = createContext(undefined);

export type PasswordAction =
	| {
			type: 'SET_EMAIL';
			payload: {
				email: string;
			};
	  }
	| {
			type: 'SET_CODE';
			payload: {
				code: number;
			};
	  }
	| {
			type: 'NEXT_STEP';
	  }
	| {
			type: 'PREVIOUS_STEP';
	  }
	| {
			type: 'RESET';
	  };

interface PasswordProviderProps {
	children?: ReactNode;
}

const initialState: PasswordState = {
	email: null,
	code: null,
	step: 1,
};

const passwordReducer = (state: PasswordState, action: PasswordAction) => {
	switch (action.type) {
		case 'SET_EMAIL':
			return {
				...state,
				email: action.payload.email,
			};
		case 'SET_CODE':
			return {
				...state,
				code: action.payload.code,
			};
		case 'NEXT_STEP':
			return {
				...state,
				step: state.step + 1,
			};
		case 'PREVIOUS_STEP':
			return {
				...state,
				step: state.step - 1,
			};
		case 'RESET':
			return {
				...state,
				email: null,
				code: null,
				step: 1,
			};
		default:
			throw new Error('Invalid action in PasswordContext');
	}
};

export const PasswordProvider = ({ children }: PasswordProviderProps) => {
	const [passwordState, passwordDispatch] = useReducer(passwordReducer, initialState);

	return <PasswordContext.Provider value={{ passwordState, passwordDispatch }}>{children}</PasswordContext.Provider>;
};

export const usePasswordContext = () => {
	const context = useContext(PasswordContext);
	if (context === undefined) {
		throw new Error('usePasswordContext must be used within a PasswordProvider');
	}
	return context;
};

import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import Page from '../../../components/Page';
import { useRegister } from '../../../context/RegisterContext';
import * as Yup from 'yup';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useState } from 'react';
import { register } from '../../../tools/server/auth/auth';
import Loader from '../../../components/Loader';
import Notice from '../../../components/Notice';
import { RegisterError } from '../../../tools/models/registerError';

const UserDetails = () => {
	const { registerState, registerDispatch } = useRegister();
	const [recaptcha, setRecaptcha] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const handle = (values: any) => {
		if (recaptcha) {
			setIsLoading(true);
			setError(null);
			register(values.username, values.password, values.email, values.firstName, values.lastName, values.description)
				.then(() => {
					registerDispatch({ type: 'NEXT_STEP' });
					setIsLoading(false);
				})
				.catch((error: RegisterError) => {
					let msg = '';
					if (error.username) {
						msg += "Ce nom d'utilisateur est déjà utilisé";
					}
					if (error.email) {
						if (msg !== '') {
							msg += '\nCette adresse email est déjà utilisée';
						} else {
							msg += 'Cette adresse email est déjà utilisée';
						}
					}
					if (msg === '') {
						msg = 'Une erreur est survenue';
					}
					setError(msg);
					setIsLoading(false);
				});
		} else {
			setError('Il semblerait que vous êtes un robot, veuillez réessayer.');
		}
	};

	const validationSchema = Yup.object().shape({
		lastName: Yup.string().required('Ce champ est obligatoire').min(2, 'Ce champ doit contenir au moins 2 caractères'),
		firstName: Yup.string().required('Ce champ est obligatoire').min(2, 'Ce champ doit contenir au moins 2 caractères'),
		username: Yup.string().required('Ce champ est obligatoire').min(3, 'Ce champ doit contenir au moins 3 caractères'),
		email: Yup.string().required('Ce champ est obligatoire').email('Ce champ doit être une adresse mail valide'),
		password: Yup.string()
			.required('Ce champ est obligatoire')
			.min(8, 'Ce champ doit contenir au moins 8 caractères')
			.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\s\S]{8,}$/, 'Ce champ doit contenir au moins 1 majuscule, 1 minuscule et 1 chiffre'),
		description: Yup.string().required('Ce champ est obligatoire').max(500, 'Ce champ doit contenir au plus 500 caractères'),
		privacy: Yup.boolean().oneOf([true], 'Vous devez accepter la politique de confidentialité'),
	});

	return (
		<Page title='Créer un compte' pageClass='registration-user-details'>
			<div className='page-registration-user-details-form'>
				<h1 className='page-registration-title'>Créer un compte</h1>
				{isLoading ? (
					<Loader />
				) : (
					<>
						{error && <Notice type='error'>{error}</Notice>}
						<Formik
							initialValues={{
								lastName: registerState.lastName,
								firstName: registerState.firstName,
								username: registerState.username,
								email: registerState.email,
								password: registerState.password,
								description: registerState.description,
								privacy: false,
							}}
							validationSchema={validationSchema}
							onSubmit={(values) => {
								registerDispatch({
									type: 'USER_DETAILS',
									payload: {
										lastName: values.lastName,
										firstName: values.firstName,
										username: values.username,
										email: values.email,
										password: values.password,
										description: values.description,
									},
								});
								handle(values);
							}}>
							<Form>
								<div className='form-group form-row'>
									<div className='form-col'>
										<div className='form-group'>
											<label htmlFor='lastName'>Nom</label>
											<Field type='text' name='lastName' id='lastName' />
											<ErrorMessage name='lastName' component='div' className='form-error' />
										</div>
									</div>
									<div className='form-col'>
										<div className='form-group'>
											<label htmlFor='firstName'>Prénom</label>
											<Field type='text' name='firstName' id='firstName' />
											<ErrorMessage name='firstName' component='div' className='form-error' />
										</div>
									</div>
								</div>
								<div className='form-group form-row'>
									<div className='form-col'>
										<div className='form-group'>
											<label htmlFor='username'>Nom d'utilisateur</label>
											<Field type='text' name='username' id='username' />
											<ErrorMessage name='username' component='div' className='form-error' />
										</div>
									</div>
									<div className='form-col'>
										<div className='form-group'>
											<label htmlFor='email'>E-mail</label>
											<Field type='email' name='email' id='email' />
											<ErrorMessage name='email' component='div' className='form-error' />
										</div>
									</div>
								</div>
								<div className='form-group'>
									<label htmlFor='password'>Mot de passe</label>
									<Field type='password' name='password' id='password' />
									<ErrorMessage name='password' component='div' className='form-error' />
								</div>
								<div className='form-group'>
									<label htmlFor='description'>Une petite description pour vous décrire</label>
									<Field name='description' as='textarea' id='description' rows={3} />
									<ErrorMessage name='description' component='div' className='form-error' />
								</div>
								<div className='form-group'>
									<label htmlFor='privacy' className='form-checkbox form-privacy'>
										<Field type='checkbox' name='privacy' id='privacy' />
										<span className='checkbox'>
											<i className='fa-solid fa-check'></i>
										</span>
										<span className='form-check-box-label'>
											J'accepte la <Link to='/privacy-policy'>politique de confidentialité des données</Link>
										</span>
									</label>
									<ErrorMessage name='privacy' component='div' className='form-error' />
								</div>
								<div className='form-group form-group-submit'>
									<button type='submit' className='form-circular-button'>
										<i className='fa-solid fa-arrow-right'></i>
									</button>
									<Link to='/login' className='button--black'>
										Déjà un compte ? Connectez-vous
									</Link>
								</div>
							</Form>
						</Formik>
					</>
				)}
			</div>
			{recaptcha === null && (
				<GoogleReCaptcha
					onVerify={(token) => {
						console.log(token);
						setRecaptcha(token);
					}}
				/>
			)}
		</Page>
	);
};

export default UserDetails;

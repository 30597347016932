import Header from '../components/Header';
import Page from '../components/Page';

const NotFound = () => {
	return (
		<Page pageClass='404' title='404'>
			<Header
				title="La page que vous demandez n'existe pas..."
				subtitle='Il semblerait que vous vous êtes perdu·e ou que cette page a été supprimée.'
				lottie={require('../assets/lottie/404.json')}
			/>
		</Page>
	);
};

export default NotFound;

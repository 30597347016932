import { ErrorMessage, Field, Form, Formik } from 'formik';
import { AuthAction, useAuth } from '../../context/AuthContext';
import * as Yup from 'yup';
import { useState } from 'react';
import Notice from '../../components/Notice';
import { update_user } from '../../tools/server/auth/auth';
import Loader from '../../components/Loader';

const Biography = () => {
	const { authState, authDispatch } = useAuth();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const validationSchema = Yup.object().shape({
		biography: Yup.string().required('Obligatoire').max(500, 'Ne peux pas dépasser 500 caractères'),
	});

	const handleSubmit = async (values: any) => {
		setErrorMessage(null);
		setIsLoading(true);
		try {
			const response = await update_user(authState.token, undefined, undefined, undefined, undefined, undefined, values.biography);
			if (response !== null) {
				const update: AuthAction = {
					type: 'RESTORE_SESSION',
					payload: {
						...authState,
						biography: response.bio,
					},
				};
				authDispatch(update);
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		} catch (errors: any) {
			setIsLoading(false);
			setErrorMessage('Une erreur est survenue lors de la mise à jour. Veuillez réessayer.');
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<Formik
			initialValues={{
				biography: authState.bio,
			}}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				handleSubmit(values);
			}}>
			<Form>
				{errorMessage && (
					<div className='form-group'>
						<Notice type='error'>{errorMessage}</Notice>
					</div>
				)}
				<div className='form-group'>
					<label htmlFor='biography'>Une petite description pour les paparazzi</label>
					<Field as='textarea' name='biography' id='biography' className='form-control'>
						{authState.bio}
					</Field>
					<ErrorMessage name='biography' component='div' className='invalid-feedback' />
				</div>
				<div className='form-group form-group-submit'>
					<button type='submit' className='button--black'>
						Sauvegarder
					</button>
				</div>
			</Form>
		</Formik>
	);
};

export default Biography;

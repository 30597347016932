import { PasswordProvider } from '../../context/PasswordContext';
import ResetPasswordDispatch from './ResetPasswordDispatch';

const LostPassword = () => {
	return (
		<PasswordProvider>
			<ResetPasswordDispatch />
		</PasswordProvider>
	);
};

export default LostPassword;

import { Context, createContext, ReactNode, useContext, useReducer } from 'react';
import { CookiesState } from '../tools/models/CookiesState';

const CookiesContext: Context<any> = createContext(undefined);

export type CookiesAction =
	| {
			type: 'SET_COOKIES';
			value: boolean;
	  }
	| {
			type: 'RESET';
	  };

interface CookiesProviderProps {
	children?: ReactNode;
}

const initialState: CookiesState = {
	cookies: localStorage.getItem('cookies') === null ? null : localStorage.getItem('cookies') === 'true' ? true : false,
};

const cookiesReducer = (state: CookiesState, action: CookiesAction) => {
	switch (action.type) {
		case 'SET_COOKIES':
			localStorage.setItem('cookies', action.value.toString());
			return {
				...state,
				cookies: action.value,
			};
		case 'RESET':
			localStorage.removeItem('cookies');
			return {
				...state,
				cookies: null,
			};
		default:
			throw new Error('Invalid action');
	}
};

export const CookiesProvider = ({ children }: CookiesProviderProps) => {
	const [cookiesState, cookiesDispatch] = useReducer(cookiesReducer, initialState);

	return <CookiesContext.Provider value={{ cookiesState, cookiesDispatch }}>{children}</CookiesContext.Provider>;
};

export const useCookiesContext = () => {
	const context = useContext(CookiesContext);
	if (context === undefined) {
		throw new Error('useCookiesContext must be used within a CookiesProvider');
	}
	return context;
};

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import Notice from '../../components/Notice';
import Page from '../../components/Page';
import VerificationCode from '../../components/VerificationCode';
import { useAuth } from '../../context/AuthContext';
import { ResponseManager } from '../../tools/models/responseManager';
import { User } from '../../tools/models/user';
import { validate_email } from '../../tools/server/auth/auth';
import { BAD_CODE } from '../../tools/server/errors';

const ValidateEmail = () => {
	const { authState, authDispatch } = useAuth();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<null | string>(null);
	const navigate = useNavigate();

	useEffect(() => {
		setIsLoading(true);
		setError(null);
		if (authState.emailValidatedAt === null) {
			setIsLoading(false);
		} else {
			navigate('/my-account');
		}
		// eslint-disable-next-line
	}, [authState.emailValidatedAt]);

	const validate = (code: number) => {
		setIsLoading(true);
		setError(null);
		validate_email(code, authState.token)
			.then((user: ResponseManager<User>) => {
				authDispatch({
					type: 'RESTORE_SESSION',
					payload: {
						...authState,
						emailValidatedAt: user.body.email_verified_at,
					},
				});
			})
			.catch((error: string) => {
				switch (error) {
					case BAD_CODE: {
						setError('Le code est incorrect');
						break;
					}
					default: {
						setError('Une erreur est survenue. Merci de réessayer plus tard');
						break;
					}
				}
				setIsLoading(false);
			});
	};

	if (isLoading) {
		return (
			<Page title='Valider son adresse mail' pageClass='email-validation'>
				<div className='container'>
					<Loader />
				</div>
			</Page>
		);
	}

	return (
		<Page title='Valider son adresse mail' pageClass='email-validation'>
			<div className='container'>
				{error && <Notice type='error'>{error}</Notice>}
				<h1 className='page-registration-title'>Confirmer son adresse e-mail</h1>
				<p className='page-registration-subtitle'>
					Pour profiter des toutes les fonctionnalités du Social O Matic, Vous devez confirmer que vous avez accès à l’adresse e-mail que vous avez fournis à
					l’aide du code à 6 Chiffres que nous vous avons envoyé. (Pensez à vérifier vos spams)
				</p>
				<div className='page-email-validation-code'>
					{authState.emailValidatedAt === null && (
						<VerificationCode
							result={(value) => {
								validate(value);
							}}
							email={authState.email}
							forPassword={false}
						/>
					)}
				</div>
			</div>
		</Page>
	);
};

export default ValidateEmail;
